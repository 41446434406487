import React from 'react'
import { Row, Divider, Col, Button } from 'antd'

interface DividerProps {
  href: string
  title: string
}
function DividerComponent({ href, title }: DividerProps) {
  return (
    <Row
      justify="center"
      style={{ paddingTop: '25px', backgroundColor: '#f9f9f9' }}
      align="middle"
    >
      <Divider style={{ margin: '10px' }} />
      <Col
        span={24}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          size="large"
          href={href}
          style={{
            display: 'flex',
            verticalAlign: 'middle',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderImage: 'linear-gradient(to right,#ef4b78, #e8c929) 1',
            borderImageSlice: 1,
            borderRadius: '3px',
            color: '#ef4b78',
            width: '200px',
            height: '50px',
            backgroundColor: 'transparent',
          }}
        >
          {' '}
          {title}
        </Button>
      </Col>
      <Divider style={{ margin: '10px' }} />
    </Row>
  )
}

export default DividerComponent
