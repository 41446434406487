import React, { useEffect } from 'react'
import { Layout, Row, Col, Typography, Button, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import './quizz.css'
// @ts-ignore
import { Helmet } from 'react-helmet'

function QuizLPNomadCheck () {
  const { Title, Paragraph } = Typography
  const { Content } = Layout
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/journey/nomad-journey-quizz')
  }
  useEffect(() => {
    document.title = 'Match with Your Ideal Coliving Experience by Nomads Tribe'
  }, [])

  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <div>
        <Helmet>
          <title>Find out if you’re a digital nomad at heart</title>
          <meta
            name='description'
            content='Find out if you’re a digital nomad at heart'
          />
        </Helmet>
        {/* your page content */}
      </div>
      <Content>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{
              backgroundColor: '#371722',
              paddingLeft: 50,
              paddingRight: 20
            }}
          >
            <Row style={{ paddingTop: 50 }}>
              <Col span={24}>
                <div className='lp-header-logo'>
                  <img
                    width='200px'
                    className='logo'
                    style={{
                      verticalAlign: 'middle'
                    }}
                    src={'/assets/beehab-logo.svg'}
                    alt='beehab logo dark'
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title
                  level={1}
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                >
                  Find out if you’re a digital nomad at heart
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontSize: 18
                  }}
                >
                  Do you crave freedom, adventure, and flexibility in your work
                  and life?
                </Paragraph>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontSize: 18
                  }}
                >
                  Take our quiz to see if you have the makings of a digital
                  nomad. Explore your personality, work style, and travel
                  preferences, and find out if you're cut out for this exciting
                  and fulfilling lifestyle.
                </Paragraph>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Col span={24}>
                <Button
                  size='large'
                  shape='round'
                  type='primary'
                  htmlType='submit'
                  style={{
                    backgroundImage:
                      'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                    border: ' 0px solid #64451a',

                    color: 'white',

                    height: '40px',
                    boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
                  }}
                  onClick={handleClick}
                >
                  Unlock your nomadic potential
                </Button>
              </Col>
            </Row>
            <Row style={{ paddingBottom: 20 }}>
              <Col span={24}>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'gray',
                    fontSize: 12
                  }}
                >
                  By doing this quiz, you agree to the BeeHab privacy policy
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={14}
            lg={14}
            xl={14}
            xxl={14}
            style={{
              backgroundColor: '#eee0d2'
            }}
          >
            <Image
              preview={false}
              src='../assets/nomad-check.png'
              height='100vh'
            ></Image>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default QuizLPNomadCheck
