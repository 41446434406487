import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Typography, Image, Button, Space } from 'antd'
import { Anchor } from 'antd'
import './coliving.css'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { useParams } from 'react-router-dom'
import { getColiving } from '../colivings/ColivingAPI'
import { Coliving, Tag } from './Coliving'
import { ASSETS_URL } from '../config'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

interface CTAProps {
  link: any
  label: string
}
interface TagSectionProps {
  tags: Tag[]
}
const TagSection = ({ tags }: TagSectionProps) => {
  return (
    <Row>
      <Col>
        <Space>
          {tags.slice(0, 4).map((tag, index) => (
            <div
              id={index.toString()}
              style={{
                color: '#64451a',
                padding: 5,
                width: 'fit-content',
                border: '1px solid #64451a',
                borderRadius: '25px',
                textAlign: 'center',
                backgroundColor: '#f5edc6'
              }}
            >
              {tag.tag}
            </div>
          ))}
        </Space>
      </Col>
    </Row>
  )
}
const CTAButton = ({ link, label }: CTAProps) => {
  return (
    <Button
      size='large'
      href={link}
      style={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        verticalAlign: 'middle',
        justifyContent: 'center',
        backgroundImage: 'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
        border: ' 0px solid #64451a',
        borderRadius: '1px',
        color: 'white',
        width: '170px',
        height: '50px',
        boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
      }}
    >
      {label}
    </Button>
  )
}
const { Title, Paragraph } = Typography
const { Content } = Layout
function ColivingPage () {
  const params = useParams()
  const colivingSlug = String(params.slug)
  const [showLogo, setShowLogo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [coliving, setColiving] = useState<Coliving>()
  const [homeTags, setHomeTags] = useState<Tag[]>([])
  const [tribeTags, setTribeTags] = useState<Tag[]>([])
  const [lifeTags, setLifeTags] = useState<Tag[]>([])
  const [cultureTags, setCultureTags] = useState<Tag[]>([])

  // manage cloving show title when user scroll down
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // change page name
  useEffect(() => {
    if (coliving) {
      document.title = '' + coliving?.name.concat(' - ', coliving.country)
    } else {
      document.title = 'Nomads Tribe find new coliving everyday'
    }
  }, [coliving])

  // handle scroll level to trigger loco visibility
  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowLogo(true)
    } else {
      setShowLogo(false)
    }
  }

  // set tags by category
  const manageTags = (tags: Tag[]) => {
    const tribeTags = tags.filter(item => item.category === 'tribe')
    setTribeTags(tribeTags)
    const homeTags = tags.filter(item => item.category === 'home')
    setHomeTags(homeTags)
    const lifeTags = tags.filter(item => item.category === 'life')
    setLifeTags(lifeTags)
    const cultureTags = tags.filter(item => item.category === 'culture')
    setCultureTags(cultureTags)
  }

  // map marker
  let myIcon = L.icon({
    iconUrl: '../assets/map-icon.png',
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [-3, -76]
  })

  L.Marker.prototype.options.icon = myIcon

  // load the coliving
  useEffect(() => {
    try {
      setLoading(true)
      setTimeout(() => {
        getColiving(colivingSlug).then(coliving => {
          setColiving(coliving)
          manageTags(coliving.tags)
          setLoading(false)
        })
      }, 1000)
    } catch (e) {
    } finally {
    }
  }, [colivingSlug])

  const LinkTitle = () => (
    <span className='coliving-title'>{coliving?.name}</span>
  )

  const items: Array<
    | { key: string; href: string; title: string }
    | { key: string; href: string; title: React.ReactNode }
  > = [
    { key: 'tribe', href: '#tribe', title: 'Tribe' },
    { key: 'home', href: '#home', title: 'Home' },
    { key: 'life', href: '#life', title: 'Life' },
    { key: 'culture', href: '#culture', title: 'Culture' }
  ]

  let displayItems = items

  if (showLogo) {
    displayItems = [
      {
        key: 'logo',
        href: 'nowhere',
        title: <LinkTitle />
      },
      ...displayItems
    ]
  }

  if (loading) {
    return (
      <Layout>
        <Content>
          <Row
            justify='center'
            style={{
              paddingTop: 65,
              height: '100vw',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Title
                    level={1}
                    className='coliving-title'
                    style={{
                      color: '#ef4b78'
                    }}
                  >
                    "It is not that we have a short time to live, but that we
                    waste a lot of it." - Seneca
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  Loading you next home ... please wait
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  } else {
    return (
      <Layout style={{ padding: 0, margin: 0 }}>
        <Content>
          <Row
            style={{
              paddingTop: 64
            }}
          >
            <Col
              span={24}
              style={{
                textAlign: 'center'
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: 470,
                  opacity: 0.5,
                  maxHeight: 470,
                  objectFit: 'cover',
                  objectPosition: '100% 50%'
                }}
                src={ASSETS_URL + coliving?.cover}
                alt={coliving?.name}
              />
              <div
                className='text-overlay'
                style={{
                  position: 'absolute',
                  zIndex: '0',
                  top: '30%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <Title
                  level={1}
                  style={{
                    color: '#ef4b78',
                    backgroundColor: 'white'
                  }}
                  className='coliving-title'
                >
                  {coliving?.name.concat(' ~ ', coliving.country)}
                </Title>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: 10,
              paddingBottom: 10
            }}
          >
            <Col
              xs={0}
              sm={0}
              md={24}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                verticalAlign: 'middle',
                textAlign: 'center'
              }}
            >
              <Anchor
                targetOffset={60}
                offsetTop={70}
                affix={true}
                items={displayItems}
                style={{
                  paddingTop: 10,
                  backgroundColor: 'white',
                  height: 50,
                  borderRadius: 5
                }}
              ></Anchor>
            </Col>
          </Row>
          <Row
            id='tribe'
            style={{
              borderTop: '1px solid #e8c929',
              borderBottom: '1px solid #e8c929'
            }}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              style={{
                maxHeight: '100%'
              }}
            >
              <Image
                preview={false}
                alt={coliving?.name}
                height='100%'
                width='100%'
                src={ASSETS_URL + coliving?.tribeCover}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  maxHeight: 540
                }}
              ></Image>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              style={{
                justifyContent: 'center',
                paddingLeft: 50,
                paddingRight: 50,
                paddingTop: 50
              }}
            >
              <Row>
                <Col span={24}>
                  <Title
                    style={{
                      fontSize: 40,
                      color: '#e8c929'
                    }}
                    className='coliving-title'
                  >
                    Tribe
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Paragraph
                    ellipsis={{
                      rows: 5,
                      expandable: false
                    }}
                    style={{
                      color: '#64451a'
                    }}
                  >
                    {coliving?.tribe}
                  </Paragraph>
                  <TagSection tags={tribeTags} />
                </Col>
              </Row>
              <Row
                style={{
                  paddingTop: 20
                }}
              >
                <Col span={24}>
                  <CTAButton link={coliving?.webSite} label='Join the tribe' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            id='home'
            style={{
              borderTop: '1px solid #e8c929',
              borderBottom: '1px solid #e8c929'
            }}
          >
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              md={{ span: 12, order: 1 }}
              span={12}
              style={{
                justifyContent: 'center',
                paddingLeft: 50,
                paddingRight: 50,
                paddingTop: 50
              }}
            >
              <Row>
                <Col span={24}>
                  <Title
                    style={{
                      fontSize: 40,
                      color: '#e8c929'
                    }}
                    className='coliving-title'
                  >
                    Home
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Paragraph
                    ellipsis={{
                      rows: 5,
                      expandable: false
                    }}
                    style={{
                      color: '#64451a'
                    }}
                  >
                    {coliving?.home}
                  </Paragraph>
                  <TagSection tags={homeTags} />
                </Col>
              </Row>
              <Row
                style={{
                  paddingTop: 20
                }}
              >
                <Col span={24}>
                  <CTAButton link={coliving?.webSite} label='Visit now' />
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 12, order: 2 }}
              span={12}
              style={{
                maxHeight: '100%'
              }}
            >
              <Image
                preview={false}
                height='100%'
                width='100%'
                alt={coliving?.name}
                src={ASSETS_URL + coliving?.homeCover}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  maxHeight: 540
                }}
              ></Image>
            </Col>
          </Row>
          <Row
            id='life'
            style={{
              borderTop: '1px solid #e8c929',
              borderBottom: '1px solid #e8c929'
            }}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              style={{
                maxHeight: '100%'
              }}
            >
              <Image
                preview={false}
                height='100%'
                width='100%'
                alt={coliving?.name}
                src={ASSETS_URL + coliving?.lifeCover}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  maxHeight: 540
                }}
              ></Image>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              style={{
                justifyContent: 'center',
                paddingLeft: 50,
                paddingRight: 50,
                paddingTop: 50
              }}
            >
              <Row>
                <Col span={24}>
                  <Title
                    style={{
                      fontSize: 40,
                      color: '#e8c929'
                    }}
                    className='coliving-title'
                  >
                    Life
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Paragraph
                    ellipsis={{
                      rows: 5,
                      expandable: false
                    }}
                    style={{
                      color: '#64451a'
                    }}
                  >
                    {coliving?.life}
                  </Paragraph>

                  <TagSection tags={lifeTags} />
                </Col>
              </Row>
              <Row
                style={{
                  paddingTop: 20
                }}
              >
                <Col span={24}>
                  <CTAButton link={coliving?.webSite} label='I want to go' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            id='culture'
            style={{
              borderTop: '1px solid #e8c929',
              borderBottom: '1px solid #e8c929',
              paddingBottom: 20
            }}
          >
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              md={{ span: 12, order: 1 }}
              span={12}
              style={{
                justifyContent: 'center',
                paddingLeft: 50,
                paddingRight: 50,
                paddingTop: 50
              }}
            >
              <Row>
                <Col span={24}>
                  <Title
                    style={{
                      fontSize: 40,
                      color: '#e8c929'
                    }}
                    className='coliving-title'
                  >
                    Culture
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Paragraph
                    ellipsis={{
                      rows: 5,
                      expandable: false
                    }}
                    style={{
                      color: '#64451a'
                    }}
                  >
                    {coliving?.culture}
                  </Paragraph>
                  <TagSection tags={cultureTags} />
                </Col>
              </Row>
              <Row
                style={{
                  paddingTop: 20
                }}
              >
                <Col span={24}>
                  <CTAButton link={coliving?.instagram} label='Instagram' />
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 12, order: 2 }}
              span={12}
              style={{
                maxHeight: '100%'
              }}
            >
              <Image
                preview={false}
                height='100%'
                width='100%'
                alt={coliving?.name}
                src={ASSETS_URL + coliving?.cultureCover}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  maxHeight: 540
                }}
              ></Image>
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: '#f5edc6',
              justifyContent: 'center',
              paddingTop: 10
            }}
          >
            <Col span={12} style={{ backgroundColor: '' }}>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Title
                    className='coliving-title'
                    style={{
                      color: '#64451a'
                    }}
                  >
                    {' '}
                    Nomads Tribe Review
                  </Title>

                  <Paragraph
                    ellipsis={{
                      rows: 5,
                      expandable: false
                    }}
                    style={{
                      color: '#64451a'
                    }}
                  >
                    Coming soon :)
                  </Paragraph>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  style={{ paddingTop: 20, paddingBottom: 20 }}
                >
                  <Image
                    preview={false}
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                      maxHeight: 200,
                      borderRadius: 20
                    }}
                    src='../assets/nomads-logo-min.png'
                  ></Image>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              borderTop: '1px solid #e8c929',
              borderBottom: '1px solid #e8c929',
              height: 500,
              paddingLeft: 20,
              paddingRight: 20
            }}
          >
            <Col span={24}>
              <div
                style={{
                  height: 500,
                  width: '100%'
                }}
              >
                {coliving && (
                  <MapContainer
                    center={[coliving.latitude, coliving.longitude]}
                    zoom={7}
                    scrollWheelZoom={false}
                    style={{ height: '100%', width: '100%' }}
                  >
                    <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                    <Marker position={[coliving.latitude, coliving.longitude]}>
                      {' '}
                    </Marker>
                  </MapContainer>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }
}
export default ColivingPage
