import React from 'react'
import { Identity } from '../quiz/Identity'
import { Card, Typography } from 'antd'
import { ASSETS_URL } from '../config'
import './component.css'

interface CardProps {
  identity: Identity
}

function MinNomadicCard({ identity }: CardProps) {
  const { Meta } = Card
  const { Paragraph } = Typography

  return (
    <Card
      style={{
        animation: 'inAnimation 2250ms ease-in',
        maxWidth: 400,
        border: '4px solid #ef4b78',
      }}
      size="default"
      type="inner"
      hoverable={true}
      loading={false}
      cover={
        <img
          src={ASSETS_URL + identity.cover}
          alt={identity.name}
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            width: '100%',
            height: 200,
            objectFit: 'cover',
          }}
        />
      }
    >
      <Meta style={{ paddingTop: 20 }} title={identity.name} />
      <Paragraph
        style={{
          paddingTop: 20,
        }}
        ellipsis={{
          rows: 5,
          expandable: true,
        }}
      >
        {identity.intro}
      </Paragraph>
    </Card>
  )
}
export default MinNomadicCard
