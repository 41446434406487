export class Story {
  id: number | undefined
  title: string = ''
  author: string = ''
  cover: string = ''
  slug: string = ''
  published: Date = new Date()
  opening: string = ''
  chapters: Chapter[] = []
  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.author) this.author = initializer.author
    if (initializer.slug) this.slug = initializer.slug
    if (initializer.title) this.title = initializer.title
    if (initializer.cover) this.cover = initializer.cover
    if (initializer.published) this.published = initializer.published
    if (initializer.opening) this.opening = initializer.opening
    if (initializer.chapters) {
      this.chapters = initializer.chapters.map(
        (chapter: any) => new Chapter(chapter),
      )
    }
  }
}

export class Chapter {
  id: number | undefined
  title: string = ''
  content: string = ''
  cover: string = ''
  created: Date = new Date()
  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.title) this.title = initializer.title
    if (initializer.cover) this.cover = initializer.cover
    if (initializer.date_created) this.created = initializer.date_created
    if (initializer.content) this.content = initializer.content
  }
}
