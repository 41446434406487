import React, { useEffect } from 'react'
import { Layout, Row, Col, Typography, Button, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import './quizz.css'
// @ts-ignore
import { Helmet } from 'react-helmet'

function QuizLPNomadType () {
  const { Title, Paragraph } = Typography
  const { Content } = Layout
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/nomad/nomad-type-quizz')
  }
  useEffect(() => {
    document.title = 'Discover what kind of digital nomad you aree'
  }, [])

  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <div>
        <Helmet>
          <title>Discover what kind of digital nomad you are</title>
          <meta
            name='description'
            content='Discover what kind of digital nomad you are'
          />
        </Helmet>
        {/* your page content */}
      </div>
      <Content>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{
              backgroundColor: '#371722',
              paddingLeft: 50,
              paddingRight: 20
            }}
          >
            <Row style={{ paddingTop: 50 }}>
              <Col span={24}>
                <div className='lp-header-logo'>
                  <img
                    width='200px'
                    className='logo'
                    style={{
                      verticalAlign: 'middle'
                    }}
                    src={'/assets/beehab-logo.svg'}
                    alt='beehab logo dark'
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title
                  level={1}
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                >
                  Discover what kind of digital nomad you are
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontSize: 18
                  }}
                >
                  Ready to find your nomadic identity?
                </Paragraph>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontSize: 18
                  }}
                >
                  Whether you're a seasoned nomad or just starting out, this
                  quiz will help you discover your unique nomadic personality
                  and the type of digital nomad lifestyle that best suits you.
                  From adventurous explorers to creative entrepreneurs, our quiz
                  will reveal the secrets of your nomadic identity and inspire
                  you to embrace the full potential of the digital nomad
                  lifestyle.
                </Paragraph>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Col span={24}>
                <Button
                  size='large'
                  shape='round'
                  type='primary'
                  htmlType='submit'
                  style={{
                    backgroundImage:
                      'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                    border: ' 0px solid #64451a',

                    color: 'white',

                    height: '40px',
                    boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
                  }}
                  onClick={handleClick}
                >
                  Discover your nomadic identity
                </Button>
              </Col>
            </Row>
            <Row style={{ paddingBottom: 20 }}>
              <Col span={24}>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'gray',
                    fontSize: 12
                  }}
                >
                  By doing this quiz, you agree to the BeeHab privacy policy
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={14}
            lg={14}
            xl={14}
            xxl={14}
            style={{
              backgroundColor: '#eee0d2'
            }}
          >
            <Image
              preview={false}
              src='../assets/nomad-quizz-min.jpg'
              height='100vh'
            ></Image>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default QuizLPNomadType
