import React from 'react'
import { Button } from 'antd'

interface ButtonProps {
  text: string
  onClick: () => void
}

function StandardButton({ text, onClick }: ButtonProps) {
  return (
    <Button
      size="large"
      onClick={onClick}
      style={{
        backgroundImage: 'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
        border: ' 0px solid #64451a',
        borderRadius: '3px',
        color: 'white',
        width: '180px',
        height: '50px',
        boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)',
      }}
    >
      {text}
    </Button>
  )
}
export default StandardButton
