import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Image, Grid, Button } from 'antd'
import {
  MdPeople,
  MdVolunteerActivism,
  MdWorkspacesFilled,
  MdMyLocation,
} from 'react-icons/md'
import { getFeaturedTribe } from '../colivings/ColivingAPI'
import { Coliving } from '../coliving/Coliving'
import { ASSETS_URL } from '../config'
import './welcome.css'

const { Title, Text, Paragraph } = Typography
const { useBreakpoint } = Grid

function FeaturedSection() {
  const [coliving, setColiving] = useState<Coliving>()
  const breakpoint = useBreakpoint()
  const anyBreakpoint =
    breakpoint.xl || breakpoint.xxl || breakpoint.lg || breakpoint.md
      ? true
      : false

  // load featured tribe
  useEffect(() => {
    try {
      getFeaturedTribe().then((coliving) => {
        setColiving(coliving)
      })
    } catch (e) {

    }
  }, [])
  return (
    <div className="featured-section">
      <Row style={{ backgroundColor: '#f9f9f9' }}>
        <Col span={24}>
          <Row>
            <Col className="centered-col" span={24}>
              <Title className="title">~ Featured Tribe ~</Title>
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{
                textAlign: anyBreakpoint ? 'right' : 'center',
              }}
            >
              <Image
                style={{
                  maxWidth: 470,
                }}
                width={anyBreakpoint ? 470 : '100%'}
                alt={coliving?.name}
                src={ASSETS_URL + coliving?.cover}
                preview={false}
                className="featured-image "
              />
              <Row align="middle" style={{}}>
                <Col
                  xs={0}
                  sm={0}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ paddingRight: '80px', paddingTop: '15px' }}
                >
                  <Image
                    style={{
                      minWidth: 200,
                      maxWidth: 400,
                    }}
                    src={ASSETS_URL + coliving?.tribeCover}
                    alt={coliving?.name}
                    preview={false}
                    className="featured-image"
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{
                textAlign: anyBreakpoint ? 'left' : 'center',
                top: anyBreakpoint ? '50px' : 'Opx',
                left: anyBreakpoint ? '-50px' : 'Opx',
              }}
            >
              <div
                style={{
                  backgroundColor: '#f5edc6',
                  width: anyBreakpoint ? 470 : '100%',
                  height: anyBreakpoint ? 470 : 410,
                  borderRadius: '4px',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      paddingLeft: '50px',
                      paddingRight: '50px',
                      paddingTop: '50px',
                      textAlign: 'left',
                    }}
                  >
                    <Row>
                      <Col>
                        <Title
                          level={2}
                          style={{
                            fontFamily: "'Welcome 2019', sans-serif",
                            color: '#64451a',
                          }}
                        >
                          {coliving?.name.concat(' ~ ', coliving.country)}
                        </Title>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div style={{}}>
                          <Paragraph
                            ellipsis={{
                              rows: 4,
                              expandable: false,
                              symbol: 'more',
                            }}
                            style={{
                              color: '#4e4e4e',
                            }}
                          >
                            {coliving?.tribe}
                          </Paragraph>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={14} style={{ paddingTop: '20px' }}>
                      <Col style={{ textAlign: 'center' }} span={4}>
                        <MdPeople size={30} color="#ef4b78" />
                        <Text style={{ fontSize: '10px' }}>Comunity</Text>
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={4}>
                        <MdVolunteerActivism
                          style={{ fontSize: '30px', color: '#ef4b78' }}
                        />
                        <Text style={{ fontSize: '10px' }}>Mindful</Text>
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={4}>
                        <MdWorkspacesFilled
                          style={{ fontSize: '30px', color: '#ef4b78' }}
                        />
                        <Text style={{ fontSize: '10px' }}>Workspace</Text>
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={4}>
                        <MdMyLocation
                          style={{ fontSize: '30px', color: '#ef4b78' }}
                        />
                        <Text style={{ fontSize: '10px' }}>Accessible</Text>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        paddingTop: 25,
                        alignItems: 'center',
                        justifyContent: anyBreakpoint ? 'left' : 'center',
                      }}
                    >
                      <Col style={{}}>
                        <Button
                          href={
                            coliving && '/colivings/'.concat(coliving?.slug)
                          }
                          size="large"
                          style={{
                            display: 'flex',

                            alignItems: 'center',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            justifyContent: 'center',
                            backgroundImage:
                              'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                            border: ' 0px solid #64451a',
                            borderRadius: '3px',
                            color: 'white',
                            width: '180px',
                            height: '50px',
                            boxShadow:
                              'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)',
                          }}
                        >
                          Read more
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default FeaturedSection
