// @ts-ignore
import { Directus } from '@directus/sdk'
import { API_URL, COLLECTION_JOINS } from '../config'
import { COLLECTION_APPLICATIONS } from '../config'

const directus = new Directus(API_URL)

// Added new user to community (applications table), Early Bird
export async function createEarlyBird (data: any): Promise<any> {
  try {
    //await directus.auth.static(token)
    const applications = await directus.items(COLLECTION_APPLICATIONS)
    const response = await applications.createOne({
      name: data.name,
      email: data.email
    })
    if (response) {
      return response
    }
  } catch (error) {
    throw new Error(error as any)
  }
}

// Added new user to community (main newsletter)
export async function joinNewsLetter (email: string): Promise<any> {
  try {
    //await directus.auth.static(token)
    const applications = await directus.items(COLLECTION_JOINS)
    const response = await applications.createOne({
      email: email
    })
    if (response) {
      return response
    }
  } catch (error) {
    throw new Error(error as any)
  }
}
