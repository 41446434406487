import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Layout, Row, Col, Typography, Image, Avatar, Divider } from 'antd'
import { getArticleBySlug } from './ArticleAPI'
import { Article } from './Article'
import { ASSETS_URL } from '../config'
import { UserOutlined } from '@ant-design/icons'
// @ts-ignore
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'
// @ts-ignore
import ProgressBar from 'react-scroll-progress-bar'
import './article.css'

function ArticlePage() {
  const params = useParams()
  const articleSlug = String(params.slug)
  const { Content } = Layout
  const { Title, Paragraph } = Typography
  const [article, setArticle] = useState<Article>()

  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }

  // load the article
  useEffect(() => {
    try {
      getArticleBySlug(articleSlug).then((article) => {
        setArticle(article)
      })
    } catch (e) {
  
    } finally {
    }
  }, [articleSlug])

  if (article) {
    return (
      <Layout style={{ padding: 0, margin: 0 }}>
        <div>
          <Helmet>
            <title>{article.title}</title>
            <meta name="description" content={article.description} />
          </Helmet>
          {/* your page content */}
        </div>
        <ProgressBar
          height="3px"
          color="blue"
          trackBackground="#ef4b78"
          borderRadius="0"
        />
        <Content style={{ paddingTop: 64 }}>
          <Row
            style={{
              paddingTop: 30,
              alignItems: 'center',
            }}
          >
            <Col
              span={24}
              style={{ alignContent: 'center', textAlign: 'center' }}
            >
              <Row>
                <Col span={24}>
                  <Image
                    preview={false}
                    alt={article?.title}
                    height="100%"
                    width="60%"
                    src={ASSETS_URL + article?.cover}
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                      maxHeight: 540,
                      borderRadius: 20,
                    }}
                  ></Image>
                </Col>
              </Row>
              <Row justify="center" style={{}}>
                <Col xs={22} sm={22} md={22} lg={10} xl={10} xxl={10}>
                  <Divider />
                  <Title
                    level={2}
                    style={{
                      fontWeight: 'normal',
                      textAlign: 'left',
                    }}
                  >
                    {article.title}
                  </Title>
                </Col>
              </Row>
              <Row justify="center">
                <Col xs={22} sm={22} md={22} lg={10} xl={10} xxl={10}>
                  <Title
                    level={4}
                    style={{
                      color: '#64451a',
                      fontWeight: 'lighter',
                      textAlign: 'left',
                    }}
                  >
                    {article.subTitle}
                  </Title>
                </Col>
              </Row>

              <Row justify="center">
                <Col
                  xs={22}
                  sm={22}
                  md={22}
                  lg={10}
                  xl={10}
                  xxl={10}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <Paragraph
                    style={{
                      fontSize: 16,
                      fontWeight: 'lighter',
                    }}
                  >
                    {parse(article.intro)}
                  </Paragraph>
                </Col>
              </Row>

              <Row justify="center">
                <Col
                  xs={22}
                  sm={22}
                  md={22}
                  lg={10}
                  xl={10}
                  xxl={10}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <blockquote>{article.quote}</blockquote>
                  <cite></cite>
                </Col>
              </Row>

              <Row justify="center">
                <Col
                  xs={22}
                  sm={22}
                  md={22}
                  lg={10}
                  xl={10}
                  xxl={10}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <Paragraph
                    style={{
                      fontSize: 16,
                      fontWeight: 'lighter',
                    }}
                  >
                    {parse(article.main)}
                  </Paragraph>
                </Col>
              </Row>
              <Row justify="center">
                <Col
                  xs={22}
                  sm={22}
                  md={22}
                  lg={10}
                  xl={10}
                  xxl={10}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <Paragraph
                    style={{
                      fontSize: 16,
                      fontWeight: 'lighter',
                    }}
                  >
                    {parse(article.outro)}
                  </Paragraph>
                </Col>
              </Row>
              <Row
                justify="center"
                style={{
                  paddingTop: 20,
                }}
              >
                <Col
                  xs={22}
                  sm={22}
                  md={22}
                  lg={10}
                  xl={10}
                  xxl={10}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <Row gutter={16}>
                    <Col>
                      {' '}
                      <Avatar
                        shape="square"
                        style={{
                          color: '#ef4b78',
                          backgroundColor: '#e8c929',
                        }}
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 32,
                          lg: 32,
                          xl: 32,
                          xxl: 32,
                        }}
                        icon={<UserOutlined />}
                      ></Avatar>
                    </Col>
                    <Col>
                      <span style={{ color: '#64451a' }}>
                        by {article.author}
                      </span>
                    </Col>
                    <Col>
                      <span style={{ color: '#64451a' }}>
                        {article.published.toLocaleDateString('en-US', options)}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }
  return <div></div>
}

export default ArticlePage
