import React, { useState, useEffect } from 'react'
import { Card, Form, Input } from 'antd'
import StandardButton from './StandardButton'
import { createEarlyBird } from '../service/JoinAPI'

function JoinCard () {
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)
  const [, setFormData] = useState({
    name: '',
    email: ''
  })
  const [form] = Form.useForm()

  useEffect(() => {
    if (sent) {
      setFormData({ name: '', email: '' })
    }
  }, [sent])

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      await addedEarlyBird(values)
    } catch (error) {}
  }

  const addedEarlyBird = async (data: any) => {
    try {
      await createEarlyBird(data)
      setSent(true)
    } catch (error) {
      setError(true)
      setSent(false)
    }
  }
  return (
    <Card
      style={{
        animation: 'inAnimation 1250ms ease-in',
        maxWidth: 400,
        border: '4px solid #ef4b78'
      }}
      size='default'
      type='inner'
      hoverable={true}
      loading={false}
      cover={
        <img
          src='../assets/community.png'
          alt='beehab community'
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            width: '100%',
            height: 200,
            objectFit: 'cover'
          }}
        />
      }
    >
      <p>
        Nomads Tribe is an authentic community of colivers and digital nomads
      </p>
      {!sent && error && <p>You are onboard! talk to you soon</p>}
      {sent && !error && <p>Your information has been sent successfully!</p>}
      {!sent && !error && (
        <>
          <Form
            form={form}
            name='basic'
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete='off'
          >
            <Form.Item
              label='Name'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Please input your name!'
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='Email'
              name='email'
              rules={[
                { required: true, message: 'Please input your Email!' },
                {
                  type: 'email',
                  message: 'Please input a valid Email!'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
          <StandardButton
            text='Apply'
            onClick={() => {
              handleSubmit()
            }}
          />
        </>
      )}
    </Card>
  )
}
export default JoinCard
