import React from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

const SearchInput = ({ onChange, value }: Props) => {
  return (
    <Input
      placeholder={'Search for a coliving'}
      value={value}
      prefix={<SearchOutlined style={{ color: '#ef4b78' }} />}
      style={{
        borderRadius: 0,
        height: '100%',
        color: '#ff0000',
        border: 0,
        width: '100%'
      }}
      onChange={onChange}
    />
  )
}
export default SearchInput
