import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Typography, Divider, Card, Button } from 'antd'
import { ASSETS_URL } from '../config'
import { Link } from 'react-router-dom'
// @ts-ignore
import { Helmet } from 'react-helmet'
import '../welcome/welcome.css'
import { Story } from './Story'
import { getStories } from './StoryAPI'

function StoriesPage () {
  const [page, setPage] = useState(1)
  const [more, setMore] = useState(true)
  const [stories, setStories] = useState<Story[]>([])
  const [loading, setLoading] = useState(false)

  /* Load initial stories batch */
  useEffect(() => {
    try {
      setLoading(true)
      /* load stories data */
      getStories(page).then((storiesList: any) => {
        setStories(storiesList)
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
    } finally {
    }
  }, [page])

  /* Handle load more */
  const handleLoadMore = () => {
    let currentPage = page + 1
    if (more) {
      setPage(currentPage)
      getStories(currentPage).then((qs: Story[]) => {
        if (Array.isArray(qs)) {
          setStories(stories.concat(qs))
        }
        if (qs.length < 1) {
          setMore(false)
        }
        setLoading(false)
      })
    }
  }

  const { Title, Paragraph } = Typography
  const { Content } = Layout
  const colors = [
    '#f9f8e6',
    '#ff8b8b',
    '#61bfad',
    '#b7e3e4',
    '#efe8d8',
    '#f3c9dd',
    '#f3c9dd',
    '#72aec5',
    '#ef3e4a',
    '#e88565',
    '#eed974'
  ]
  return (
    <Layout>
      <div>
        <Helmet>
          <title>
            Inspiring Stories of Digital Wanderers and Community Explorers by
            Nomads Tribe
          </title>
          <meta
            name='description'
            content='Inspiring tales of digital nomads and coliving members who wander
              the world and explore new communities. We offer insights and
              advice on remote work, coliving, and travel, as well as personal
              stories of adventure and growth. Join us on a journey of discovery
              and connection'
          />
        </Helmet>
      </div>
      <Content
        style={{
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        <Row
          justify='center'
          style={{
            paddingTop: 60,
            alignItems: 'center'
          }}
        >
          <Col
            xs={22}
            sm={22}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{ alignContent: 'center', textAlign: 'center' }}
          >
            <Title
              level={2}
              className='title-3'
              style={{
                color: '#64451a',
                fontWeight: 'lighter'
              }}
            >
              Inspiring <span className='title'>Stories</span> of Digital
              Wanderers and Community Explorers
            </Title>
            <Paragraph
              style={{
                paddingTop: 15,
                color: '#535353',
                fontSize: 15,
                fontWeight: 'lighter'
              }}
            >
              Inspiring tales of digital nomads and coliving members who wander
              the world and explore new communities. We offer insights and
              advice on remote work, coliving, and travel, as well as personal
              stories of adventure and growth. Join us on a journey of discovery
              and connection
            </Paragraph>
          </Col>
        </Row>
        <Divider></Divider>
        <Row gutter={[16, 16]}>
          {!loading &&
            stories.length > 0 &&
            stories.map((story, index) => (
              <Col id={index.toString()}>
                <Link to={'/story/'.concat(story.slug)} id={index.toString()}>
                  <Card
                    id={index.toString()}
                    style={{
                      width: 300,
                      height: 'auto',
                      backgroundColor:
                        colors[
                          Math.floor(Math.random() * colors.length)
                        ].concat('80'),
                      opacity: 1
                    }}
                    cover={
                      <img
                        alt='example'
                        src={ASSETS_URL + story.cover}
                        width='300px'
                        height='200px'
                        style={{
                          objectFit: 'cover'
                        }}
                      />
                    }
                  >
                    <span
                      style={{
                        fontSize: 16
                      }}
                    >
                      {story.title}
                    </span>
                  </Card>
                </Link>
              </Col>
            ))}
        </Row>
        <Row
          style={{
            paddingTop: 30
          }}
        >
          <Col
            span={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button
              size='large'
              onClick={handleLoadMore}
              style={{
                display: 'flex',
                verticalAlign: 'middle',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid',
                borderImage: 'linear-gradient(to right,#ef4b78, #e8c929) 1',
                borderImageSlice: 1,
                borderRadius: '3px',
                color: '#ef4b78',
                width: '200px',
                height: '50px',
                backgroundColor: 'transparent'
              }}
            >
              {' '}
              {more ? 'More ...' : 'No More :)'}
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default StoriesPage
