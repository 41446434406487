// @ts-ignore
import { Directus } from '@directus/sdk'
import { Question } from './Quiz'
import { API_URL, COLLECTION_COLIVINGS } from '../config'
import { Coliving } from '../coliving/Coliving'
import { Identity } from './Identity'
import { COLLECTION_QUESTIONS, COLLECTION_IDENTITIES } from '../config'

const directus = new Directus(API_URL)


// Get all questions by category and map to Question Class
export async function getQuestions (category: string) {
  //await directus.auth.static(token)
  const questionCollection = await directus.items(COLLECTION_QUESTIONS)
  const response = await questionCollection.readByQuery({
    fields: ['question,id, order, status, answers.answer, answers.id, answers.tags.Tags_id.*, answers.order'],
    filter: {
      category: {
        _eq: category
      }
    }
  })

  const questionsData = response['data']

  if (questionsData) {
    const questions: Question[] = questionsData.map((question: any) => {
      return new Question(question)
    })
    return questions
  } else {
    let questions: Question[] = []
    return questions
  }
}

// Get (one) coliving information min version (only needed information for min card)
export async function getIdentity (tagsId: Number[]): Promise<any> {
  
  //await directus.auth.static(token)
  const identityId = await getMatchedIdentity(tagsId)
  const identityCollection = await directus.items(COLLECTION_IDENTITIES)
  const response = await identityCollection.readOne(identityId, {
    fields: ['id,intro,name,cover']
  })
  if (response) {
    return new Identity(response)
  } else {
    return new Identity()
  }
}

// Get matched
async function getMatchedIdentity (tagIds: Number[]): Promise<any> {
  let maxMatchingIdentityId = -1
  let maxMatchingCount = -1


  //await directus.auth.static(token)
  const identityCollection = await directus.items(COLLECTION_IDENTITIES)
  const response = await identityCollection.readByQuery({
    fields: ['id,tags.tags_id.id'], // filter[tags][tags_id][id][_in]
    filter: {
      tags: {
        Tags_id: {
          id: {
            _in: tagIds
          }
        }
      }
    }
  })

  if (response.data) {

    const simplifiedData: { [key: number]: number[] } = {}
    response.data.forEach((identity: any) => {
      simplifiedData[identity.id] = identity.tags.map(
        (tag: any) =>{
        return tag
      }
      )
    })

    for (const [identityId, identityTagIds] of Object.entries(simplifiedData)) {
      let matchingCount = 0
      identityTagIds.forEach(identityTagId => {
        if (tagIds.includes(identityTagId)) {
          matchingCount++
        }
      })
      if (matchingCount > maxMatchingCount) {
        maxMatchingCount = matchingCount
        maxMatchingIdentityId = Number(identityId)
      }
    }
  }
  return maxMatchingIdentityId
}

// get (one) coliving information min version (only needed information for min card)
export async function getColiving (tagsId: Number[]): Promise<any> {
  //await directus.auth.static(token)
  const colivingId = await getMatchedColiving(tagsId)
  const colivingCollection = await directus.items(COLLECTION_COLIVINGS)
  const response = await colivingCollection.readOne(colivingId, {
    fields: ['id,intro,name,slug,logo,cover,tags.*.tag']
  })
  if (response) {
    return new Coliving(response)
  } else {
    return new Coliving()
  }
}

// get (all) coliving id, and respective tags id
async function getMatchedColiving (tagIds: Number[]): Promise<any> {
  let maxMatchingColivingId = -1
  let maxMatchingCount = -1

  //await directus.auth.static(token)
  const colivingCollection = await directus.items(COLLECTION_COLIVINGS)
  const response = await colivingCollection.readByQuery({
    fields: ['id,tags.tags_id.id'], // filter[tags][tags_id][id][_in]
    filter: {
      tags: {
        Tags_id: {
          id: {
            _in: tagIds
          }
        }
      }
    }
  })

  if (response.data) {
    const simplifiedData: { [key: number]: number[] } = {}
    response.data.forEach((coliving: any) => {
      simplifiedData[coliving.id] = coliving.tags.map(
        (tag: any) =>{
        return tag
      }
      )
    })

    for (const [colivingId, colivingTagIds] of Object.entries(simplifiedData)) {
      let matchingCount = 0
      colivingTagIds.forEach(colivingTagId => {
        if (tagIds.includes(colivingTagId)) {
          matchingCount++
        }
      })
      if (matchingCount > maxMatchingCount) {
        maxMatchingCount = matchingCount
        maxMatchingColivingId = Number(colivingId)
      }
    }
  }
  return maxMatchingColivingId
}
