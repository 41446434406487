import React, { useEffect, useState, useCallback } from 'react'
import { Layout, Row, Col, Typography, Button, Divider, Grid } from 'antd'
import '../coliving/coliving.css'
import './searchBar.css'

import { getColivingList, getLocation, getTags } from './ColivingAPI'
import { Coliving, Location, TagRef } from '../coliving/Coliving'
import StandardColivingCard from '../components/StandardColivingCard'
import SearchInput from './SearchComponent'
import CountrySelect from './LocationComponent'
import StandardSelect from './StandardSelectComponent'

const MapViewButton = () => {
  return (
    <Button
      style={{
        backgroundColor: 'transparent',
        border: '1px solid',
        borderColor: '#ef4b78',
        alignItems: 'center',
        textAlign: 'center',
        verticalAlign: 'middle',
        justifyContent: 'center',
        borderRadius: 5,
        color: '#ef4b78',
        width: '100%',
        maxWidth: '140px',
        fontWeight: 'bold'
      }}
      onClick={() => {
        console.log('Map View button clicked')
      }}
      href='experience/'
    >
      Map View
    </Button>
  )
}

const SearchButton = () => {
  return (
    <Button
      style={{
        backgroundImage: 'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
        border: ' 0px solid #64451a',
        borderRadius: 5,
        color: 'white',
        width: '70%',
        height: '40px',
        boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
      }}
    >
      Search
    </Button>
  )
}

function ColivingsPage () {
  /* Page Title */
  useEffect(() => {
    document.title = 'Hand-curated coliving listings'
  })

  /* Store colivings list result */
  const [colivingList, setColivingsList] = useState<Coliving[]>([])
  const [loading, setLoading] = useState(false)
  const [locationsList, setLocationsList] = useState<Location[]>([])
  const [homeTags, setHometags] = useState<TagRef[]>([])
  const [lifeTags, setLifeTags] = useState<TagRef[]>([])
  const [serviceTags, setServiceTags] = useState<TagRef[]>([])
  const [prices, setPrices] = useState<TagRef[]>([])
  const [reset, setReset] = useState(false)
  const [page, setPage] = useState(1)
  const [more, setMore] = useState(true)
  const { useBreakpoint } = Grid
  const breakpoint = useBreakpoint()
  const anyBreakpoint = breakpoint.xs ? true : false
  const { Content } = Layout
  const { Title } = Typography

  /* manage nested complexe filtering */

  const [filters, setFilters] = useState({
    search: '',
    locations: [] as number[],
    tags: [] as number[]
  })

  const loadColivings = useCallback(async () => {
    setLoading(true)
    setTimeout(() => {
      getColivingList(filters, 1).then((qs: Coliving[]) => {
        setColivingsList(qs)
        setLoading(false)
        setReset(false)
      })
    }, 0)
  }, [filters])

  /* Handle load more */
  const handleLoadMore = () => {
    let currentPage = page + 1
    if (more) {
      setPage(currentPage)
      getColivingList(filters, currentPage).then((qs: Coliving[]) => {
        if (Array.isArray(qs)) {
          setColivingsList(colivingList.concat(qs))
        }
        if (qs.length < 1) {
          setMore(false)
        }
        setLoading(false)
        setReset(false)
      })
    }
  }

  useEffect(() => {
    setMore(true)
    setPage(1)
  }, [filters])

  /* Handle search */
  const handleSearchChange = (event: any) => {
    setFilters({
      ...filters,
      search: event.target.value
    })
  }

  /* Handle location  */
  const handleLocationChange = (selectedIds: number[]) => {
    setFilters({ ...filters, locations: selectedIds })
  }

  /* Handle tags selections */
  const handleTagChange = (selectedIds: number[]) => {
    setFilters({ ...filters, tags: selectedIds })
  }

  /* handle clear all */
  const handleClearAll = () => {
    setReset(true)
    setFilters({
      search: '',
      locations: [],
      tags: []
    })
    setPage(1)
    setMore(true)
    loadColivings()
  }

  /* effet to load colivings */
  useEffect(() => {
    try {
      /* load coliving data*/

      loadColivings()
      /* load locations list */
      getLocation().then((locations: any) => {
        setLocationsList(locations)
      })
      /* load prices tags*/

      getTags('price').then((prices: any) => {
        setPrices(prices)
      })
      /* load environment tag : home */
      getTags('home').then((homes: any) => {
        setHometags(homes)
      })
      /* load activities tag : home */
      getTags('life').then((lifes: any) => {
        setLifeTags(lifes)
      })
      /* load offering tag : home */
      getTags('service').then((services: any) => {
        setServiceTags(services)
      })
    } catch (e) {
    } finally {
    }
  }, [loadColivings])

  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <Content>
        <Row
          style={{
            paddingTop: 64,
            paddingBottom: 20
          }}
        >
          <Col
            span={24}
            style={{
              textAlign: 'center',
              paddingTop: 40
            }}
          >
            {!anyBreakpoint && (
              <div>
                <img
                  style={{
                    paddingTop: 10,
                    width: '100%',
                    height: 200,
                    maxHeight: 200,
                    objectFit: 'contain',
                    objectPosition: '100% 20%'
                  }}
                  src='../assets/kosmos-head.png'
                  alt='Nomads Tribe Kosmos Head'
                />
              </div>
            )}

            {anyBreakpoint && (
              <div
                style={{
                  height: 200,
                  width: '100%',
                  objectPosition: '100% 20%'
                }}
              ></div>
            )}

            <div
              className='text-overlay'
              style={{
                position: 'absolute',
                zIndex: '0',
                top: '50%',
                left: '50%',
                width: '100%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Title
                level={1}
                style={{
                  color: '#ef4b78'
                }}
                className='title'
              >
                Find your next home
              </Title>
              <p
                style={{
                  color: '#64451a'
                }}
              >
                Join our vibrant community. Our coliving listings are
                hand-curated and voted on by the community.
              </p>

              {anyBreakpoint && (
                <Row justify='center' style={{}}>
                  <Col span={23} style={{}}>
                    <Row
                      style={{
                        width: '100%'
                      }}
                    >
                      <Col span={24}>
                        <SearchInput
                          onChange={handleSearchChange}
                          value={filters.search}
                        />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        paddingTop: 5,
                        width: '100%'
                      }}
                    >
                      <Col span={24}>
                        <CountrySelect
                          onChange={handleLocationChange}
                          locations={locationsList}
                          reset={reset}
                        />
                      </Col>
                    </Row>
                    <Row
                      justify='center'
                      style={{
                        paddingTop: 5,
                        width: '100%'
                      }}
                    >
                      <Col span={12}>
                        <StandardSelect
                          placeHolder='💰Average price'
                          tags={prices}
                          onChange={handleTagChange}
                          reset={reset}
                        />
                      </Col>
                      <Col span={12}>
                        <StandardSelect
                          placeHolder='🏡 Environment'
                          tags={homeTags}
                          onChange={handleTagChange}
                          reset={reset}
                        />
                      </Col>
                    </Row>
                    <Row
                      justify='center'
                      style={{
                        paddingTop: 5,
                        width: '100%'
                      }}
                    >
                      <Col span={12}>
                        <StandardSelect
                          placeHolder='🏔️ Activities'
                          tags={lifeTags}
                          onChange={handleTagChange}
                          reset={reset}
                        />
                      </Col>
                      <Col span={12}>
                        <StandardSelect
                          placeHolder='🛎️ Offering'
                          tags={serviceTags}
                          onChange={handleTagChange}
                          reset={reset}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {!anyBreakpoint && (
                <Row
                  justify='center'
                  style={{
                    height: 100
                  }}
                >
                  <Col
                    span={16}
                    style={{
                      backgroundColor: 'white'
                    }}
                  >
                    <Row
                      style={{
                        height: '50%'
                      }}
                    >
                      <Col span={10} style={{ height: '100%' }}>
                        <SearchInput
                          onChange={handleSearchChange}
                          value={filters.search}
                        />
                      </Col>

                      <Col span={10} style={{ height: '100%' }}>
                        <CountrySelect
                          onChange={handleLocationChange}
                          locations={locationsList}
                          reset={reset}
                        />
                      </Col>
                      <Col
                        span={4}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <SearchButton />
                      </Col>
                    </Row>
                    <Divider
                      style={{
                        padding: 0,
                        marginTop: 1,
                        marginBottom: 1
                      }}
                    />
                    <Row
                      style={{ height: '45%', paddingTop: 3, paddingLeft: 5 }}
                      gutter={16}
                    >
                      <Col span={4}>
                        <StandardSelect
                          placeHolder='💰 Average price'
                          tags={prices}
                          onChange={handleTagChange}
                          reset={reset}
                        />
                      </Col>
                      <Col span={4}>
                        <StandardSelect
                          placeHolder='🏡 Environment'
                          tags={homeTags}
                          onChange={handleTagChange}
                          reset={reset}
                        />
                      </Col>
                      <Col span={4}>
                        <StandardSelect
                          placeHolder='🏔️ Activities'
                          tags={lifeTags}
                          onChange={handleTagChange}
                          reset={reset}
                        />
                      </Col>
                      <Col span={4}>
                        <StandardSelect
                          placeHolder='🛎️ Offering'
                          tags={serviceTags}
                          onChange={handleTagChange}
                          reset={reset}
                        />
                      </Col>
                      <Col span={4}>
                        <Button
                          type='text'
                          onClick={handleClearAll}
                          style={{
                            color: 'gray'
                          }}
                        >
                          Clear all
                        </Button>
                      </Col>
                      <Col span={4} style={{ paddingRight: 20 }}>
                        <MapViewButton />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
        <Divider></Divider>
        <Row align='middle' justify='center'>
          <Col xs={23} sm={23} md={18} lg={18} xl={18} xxl={18} style={{}}>
            <Row align='middle' justify='center' gutter={[16, 16]}>
              {loading && (
                <div style={{ paddingTop: 400 }}>
                  <div className='loading'>
                    <div className='circle'></div>
                    <div className='circle'></div>
                    <div className='circle'></div>
                    <div className='circle'></div>
                  </div>
                </div>
              )}

              {!loading &&
                colivingList.length > 0 &&
                colivingList.map((coliving, index) => (
                  <Col
                    xs={24}
                    sm={24}
                    md={10}
                    lg={10}
                    xl={7}
                    xxl={7}
                    key={index}
                  >
                    <StandardColivingCard key={index} coliving={coliving} />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: 30
          }}
        >
          <Col
            span={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button
              size='large'
              onClick={handleLoadMore}
              style={{
                display: 'flex',
                verticalAlign: 'middle',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid',
                borderImage: 'linear-gradient(to right,#ef4b78, #e8c929) 1',
                borderImageSlice: 1,
                borderRadius: '3px',
                color: '#ef4b78',
                width: '200px',
                height: '50px',
                backgroundColor: 'transparent'
              }}
            >
              {' '}
              {more ? 'More ...' : 'No More :)'}
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ColivingsPage
