export const API_URL: string =
  process.env.REACT_APP_DIRECTUS_API_URL ?? 'https://bo.beehab.co'
export const API_TOKEN: string =
  process.env.REACT_APP_DIRECTUS_ACCESS_TOKEN ?? ''
export const ASSETS_URL: string =
  process.env.REACT_APP_DIRECTUS_ASSETS_URL ?? 'https://bo.beehab.co/assets/'

// COLLECTION NAMES
export const COLLECTION_COLIVINGS: string = 'Colivings'
export const COLLECTION_ARTICLES: string = 'Articles'
export const COLLECTION_CATEGORIES: string = 'Categories'
export const COLLECTION_TAGS: string = 'Tags'
export const COLLECTION_LOCATIONS: string = 'Locations'
export const COLLECTION_APPLICATIONS: string = 'Applications'
export const COLLECTION_JOINS: string = 'Joins'
export const COLLECTION_COMMUNITIES: string = 'Communities'
export const COLLECTION_QUESTIONS: string = 'Questions'
export const COLLECTION_IDENTITIES: string = 'Identities'
export const COLLECTION_STORIES: string = 'Stories'
