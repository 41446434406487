import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { Location } from '../coliving/Coliving'
import {
  SearchOutlined,
  PlusCircleOutlined,
  CloseCircleFilled,
} from '@ant-design/icons'

interface Props {
  locations: Location[]
  onChange: (selectedIds: number[]) => void
  reset: boolean
}

const CountrySelect = ({ locations, onChange, reset }: Props) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const handleSelectChange = (selectedValues: any[]) => {
    const selectedIds = selectedValues.map((value) => value)
    setSelectedValues(selectedValues)
    onChange(selectedIds)
  }
  const filterOption = (input: string, option: any) =>
    option.children.toLowerCase().includes(input.toLowerCase())

  useEffect(() => {
    if (reset) {
      setSelectedValues([])
    }
  }, [reset])

  return (
    <Select
      mode="multiple"
      value={selectedValues}
      onChange={handleSelectChange}
      allowClear={true}
      placeholder="🌍 Country or Region "
      size="large"
      maxTagCount="responsive"
      filterOption={filterOption}
      suffixIcon={<SearchOutlined style={{ color: '#ef4b78' }} />}
      removeIcon={<PlusCircleOutlined style={{ color: 'red' }} />}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        display: 'block',
        paddingBottom: 0,
        textAlign: 'left',
        borderLeft: 0,
        borderRight: 0,
        borderTop: '1px solid white',
        borderBottom: '1px solid white',
      }}
      clearIcon={<CloseCircleFilled style={{ color: '#ef4b78' }} />}
    >
      {locations.map((location) => (
        <Select.Option key={location.id} value={location.id}>
          {location.location}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CountrySelect
