export class Question {
  id: number | undefined
  category: string = ''
  question: string = ''
  order: number | undefined
  answers: Answer[] = []

  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.category) this.category = initializer.category
    if (initializer.question) this.question = initializer.question
    if (initializer.order) this.order = initializer.order
    if (initializer.answers) {
      this.answers = initializer.answers.map((a: any) => new Answer(a))
    }
  }
}

export class Answer {
  id: number | undefined
  answer: string = ''
  tags: Tag[] = []
  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.answer) this.answer = initializer.answer
    if (initializer.tags) this.tags = initializer.tags.map((t: any) => new Tag(t.Tags_id))
  }
}

export class Tag {
  id: number | undefined
  tag: string = ''
  category: string = ''

  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.tag) this.tag = initializer.tag
    if (initializer) this.category = initializer.category
  }
}
