export class Identity {
  id: number | undefined
  name: string = ''
  cover: string = ''
  intro: number | undefined

  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.name) this.name = initializer.name
    if (initializer.cover) this.cover = initializer.cover
    if (initializer.intro) this.intro = initializer.intro
  }
}
