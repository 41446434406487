import React, { useEffect, useState } from 'react'
import { Layout, Row, Col, Typography, Collapse, Button } from 'antd'
import '../coliving/coliving.css'
// @ts-ignore
import { Helmet } from 'react-helmet'
// @ts-ignore
import ProgressBar from 'react-scroll-progress-bar'
import '../welcome/welcome.css'
import ReactMarkdown from 'react-markdown'
import manifesto from './content/manifesto.md'
import questionOne from './content/question-one.md'
import questionTwo from './content/question-two.md'
import questionThree from './content/question-three.md'
import '../welcome/welcome.css'

const panelStyle = {
  backgroundColor: '#f9f9F9',
  fontSize: 16,
  fontWeight: 'lighter'
}

const panelButton = () => {
  return (
    <Button
      size='small'
      style={{
        backgroundImage: 'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
        border: ' 0px solid #64451a',
        borderRadius: '3px',
        color: 'white',
        width: '120px',
        height: '30px',
        boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
      }}
    >
      Learn more
    </Button>
  )
}

function FAQPage () {
  const { Content } = Layout
  const { Title, Paragraph } = Typography
  const { Panel } = Collapse
  const onChange = (key: string | string[]) => {}
  const [manifestoText, setManifestoText] = useState('')
  const [oneText, setOneText] = useState('')
  const [twoText, setTwoText] = useState('')
  const [threeText, setThreeText] = useState('')

  // Fetch manifesto intro
  useEffect(() => {
    fetch(manifesto)
      .then(res => res.text())
      .then(text => setManifestoText(text))
  }, [])
  useEffect(() => {
    fetch(questionOne)
      .then(res => res.text())
      .then(text => setOneText(text))
  }, [])

  useEffect(() => {
    fetch(questionTwo)
      .then(res => res.text())
      .then(text => setTwoText(text))
  }, [])
  useEffect(() => {
    fetch(questionThree)
      .then(res => res.text())
      .then(text => setThreeText(text))
  }, [])
  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <div>
        <Helmet>
          <title>Nomads Tribe FAQ </title>
          <meta
            name='description'
            content='Nomads Tribe is website created by nomads for nomads, in the begining it start as a creative project and learning platform'
          />
        </Helmet>
        {/* your page content */}
      </div>
      <ProgressBar
        height='3px'
        color='blue'
        trackBackground='#ef4b78'
        borderRadius='0'
      />
      <Content>
        <Row
          justify='center'
          style={{
            paddingTop: 60,
            alignItems: 'center'
          }}
        >
          <Col
            xs={22}
            sm={22}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{ alignContent: 'center', textAlign: 'center' }}
          >
            <Title
              level={2}
              className='title-3'
              style={{
                color: '#64451a',
                fontWeight: 'lighter'
              }}
            >
              Nomads Tribe <span className='title'>FAQ</span>
            </Title>
            <Paragraph
              style={{
                paddingTop: 15,
                color: '#535353',
                fontSize: 15,
                fontWeight: 'lighter'
              }}
            >
              <ReactMarkdown children={manifestoText} />
            </Paragraph>
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs={23} sm={23} md={16} lg={16} xl={16} xxl={16}>
            <Collapse
              defaultActiveKey={['1']}
              onChange={onChange}
              style={{}}
              expandIconPosition='end'
            >
              <Panel
                showArrow={false}
                style={panelStyle}
                header='👋 Who are you and why did you create this website?'
                key='1'
                extra={panelButton()}
              >
                <ReactMarkdown children={oneText} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='😊 How do you choose the co-living spaces listed on Nomads Tribe?'
                key='2'
                extra={panelButton()}
              >
                <ReactMarkdown children={twoText} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='🏡 How can I recommend a new co-living space to be listed on Nomads Tribe?'
                key='3'
                extra={panelButton()}
              >
                <ReactMarkdown children={threeText} />
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row
          justify='center'
          style={{
            paddingTop: 60,
            alignItems: 'center'
          }}
        >
          <Col
            xs={22}
            sm={22}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{ alignContent: 'center', textAlign: 'center' }}
          >
            <Title
              level={2}
              className='title-3'
              style={{
                color: '#64451a',
                fontWeight: 'lighter'
              }}
            >
              Thank You
            </Title>
            <Paragraph
              style={{
                paddingTop: 15,
                color: '#535353',
                fontSize: 15,
                fontWeight: 'lighter'
              }}
            >
              If you have any further questions that have not been answered in
              this FAQ section, please feel free to reach out to us at
              hi@beehab.co . We look forward to hearing from you!
            </Paragraph>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}
export default FAQPage
