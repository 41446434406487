import React from 'react'
import './notfound.css'

function NotFoundPage () {
  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: 'red'
      }}
    >
      <div className='glitch-wrapper'>
        <div className='glitch'>404 You're lost.</div>
      </div>

      <div className='msg'>
        Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
        existed in the first place?
        <p>
          Let's go{' '}
          <a
            style={{
              color: '#ef4b78'
            }}
            href='/'
          >
            home
          </a>{' '}
          and try from there.
        </p>
      </div>
    </div>
  )
}

export default NotFoundPage
