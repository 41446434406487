import React, { useState, useEffect } from 'react'
import {
  Typography,
  Divider,
  Carousel,
  Layout,
  Row,
  Col,
  Image,
  Button
} from 'antd'
import './story.css'
import '../welcome/welcome.css'
import { CarouselRef } from 'antd/es/carousel'
import { Story } from './Story'
import { getStoryBySlug } from './StoryAPI'
import { useParams } from 'react-router-dom'
// @ts-ignore
import { Helmet } from 'react-helmet'
import { ASSETS_URL } from '../config'
import ReactMarkdown from 'react-markdown'

function StoryPage () {
  const { Content } = Layout
  const { Title, Paragraph } = Typography
  const params = useParams()
  const storySlug = String(params.slug)
  const [story, setStory] = useState<Story>()

  const onChange = (currentSlide: number) => {}

  const carouselRef = React.useRef<CarouselRef>(null)

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next()
    }
  }

  // load the article
  useEffect(() => {
    try {
      getStoryBySlug(storySlug).then(st => {
        setStory(st)
      })
    } catch (e) {
    } finally {
    }
  }, [storySlug])

  if (story) {
    return (
      <Layout
        style={{
          backgroundImage: 'url(../assets/story/bg.jpg)',
          objectFit: 'cover',
          height: '110vh'
        }}
      >
        <div>
          <Helmet>
            <title>{story.title}</title>
            <meta
              name='description'
              content='stories by digital nomad to digital nomad'
            />
          </Helmet>
          {/* your page content */}
        </div>
        <Content>
          <Carousel
            ref={carouselRef}
            effect='scrollx'
            dotPosition='top'
            afterChange={onChange}
            style={{
              paddingLeft: 5,
              paddingRight: 5
            }}
          >
            <div style={{}}>
              <Row
                justify='center'
                style={{
                  paddingTop: 120
                }}
              >
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'white',
                      maxWidth: '800px',
                      borderRadius: 20,
                      paddingBottom: 20
                    }}
                  >
                    <Image
                      height={300}
                      preview={false}
                      width='100%'
                      src={ASSETS_URL + story.cover}
                      alt='nomads tribe stories'
                      style={{
                        borderRadius: 20,
                        objectFit: 'cover',
                        width: '100%',
                        height: 300,
                        margin: 'auto',
                        display: 'block',
                        maxWidth: '800px'
                      }}
                    />
                    <Title
                      level={1}
                      style={{
                        color: '#ef4b78',
                        textAlign: 'left',
                        paddingLeft: 20,
                        paddingRight: 20
                      }}
                    >
                      {story.title}
                    </Title>

                    <Paragraph
                      style={{
                        fontSize: 16,
                        fontWeight: 'lighter',
                        textAlign: 'left',
                        paddingLeft: 20,
                        paddingRight: 20
                      }}
                    >
                      <ReactMarkdown children={story.opening} />
                    </Paragraph>
                    <Divider style={{ margin: '10px' }} />
                    <Button
                      size='large'
                      onClick={handleNext}
                      style={{
                        border: '1px solid',
                        borderImage:
                          'linear-gradient(to right,#ef4b78, #e8c929) 1',
                        borderImageSlice: 1,
                        borderRadius: '3px',
                        color: '#ef4b78',
                        width: '200px',
                        height: '50px',
                        backgroundColor: 'transparent'
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            {story.chapters &&
              story.chapters.map(chapter => (
                <div>
                  <Row
                    justify='center'
                    style={{
                      paddingTop: 120
                    }}
                  >
                    <Col
                      span={24}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center'
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: 'white',
                          maxWidth: '800px',
                          borderRadius: 20,
                          paddingBottom: 20
                        }}
                      >
                        {chapter.cover && (
                          <Image
                            height={300}
                            preview={false}
                            width='100%'
                            src={ASSETS_URL + chapter.cover}
                            alt='nomads tribe stories'
                            style={{
                              borderRadius: 20,
                              objectFit: 'cover',
                              width: '100%',
                              height: 300,
                              margin: 'auto',
                              display: 'block',
                              maxWidth: '800px'
                            }}
                          />
                        )}
                        {chapter.title && (
                          <Title
                            level={1}
                            style={{
                              color: '#ef4b78',
                              textAlign: 'left',
                              paddingLeft: 20,
                              paddingRight: 10
                            }}
                          >
                            {chapter.title}
                          </Title>
                        )}

                        {chapter.content && (
                          <Paragraph
                            style={{
                              fontSize: 16,
                              fontWeight: 'lighter',
                              textAlign: 'left',
                              paddingTop: 30,
                              paddingLeft: 20,
                              paddingRight: 10
                            }}
                          >
                            <ReactMarkdown children={chapter.content} />
                          </Paragraph>
                        )}

                        <Divider style={{ margin: '10px' }} />
                        <Button
                          size='large'
                          onClick={handleNext}
                          style={{
                            border: '1px solid',
                            borderImage:
                              'linear-gradient(to right,#ef4b78, #e8c929) 1',
                            borderImageSlice: 1,
                            borderRadius: '3px',
                            color: '#ef4b78',
                            width: '200px',
                            height: '50px',
                            backgroundColor: 'transparent'
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
          </Carousel>
        </Content>
      </Layout>
    )
  }
  return <div></div>
}

export default StoryPage
