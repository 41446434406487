import React from 'react'
import { Coliving } from '../coliving/Coliving'
import { Card, Typography, Space, Tag, Avatar } from 'antd'
import { ASSETS_URL } from '../config'
import { Link } from 'react-router-dom'

interface CardProps {
  coliving: Coliving
}

function StandardColivingCard({ coliving }: CardProps) {
  const { Meta } = Card
  const { Paragraph } = Typography

  return (
    <Link to={'/colivings/'.concat(coliving.slug)}>
      <Card
        style={{}}
        key={coliving.id}
        size="default"
        type="inner"
        hoverable={true}
        loading={false}
        cover={
          <img
            src={ASSETS_URL + coliving.cover}
            alt={coliving.name}
            style={{
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              width: '100%',
              height: 200,
              objectFit: 'cover',
            }}
          />
        }
      >
        <Space size={[0, 8]} wrap>
          {coliving.tags.slice(0, 3).map((tag) => (
            <Tag key={tag.id} style={{ backgroundColor: 'transparent' }}>
              {' '}
              {tag.tag}{' '}
            </Tag>
          ))}
        </Space>
        <Meta
          style={{ paddingTop: 20 }}
          avatar={<Avatar src={ASSETS_URL + coliving.logo}></Avatar>}
          title={coliving.name.concat(' ~ ', coliving.country)}
        />
        <Paragraph
          style={{
            paddingTop: 20,
          }}
          ellipsis={{
            rows: 3,
            expandable: false,
          }}
        >
          {coliving.intro}
        </Paragraph>
      </Card>
    </Link>
  )
}

export default StandardColivingCard
