import React, { useEffect, useState } from 'react'
import { Layout, Row, Col, Typography, Collapse, Button } from 'antd'
import '../coliving/coliving.css'
// @ts-ignore
import { Helmet } from 'react-helmet'
// @ts-ignore
import ProgressBar from 'react-scroll-progress-bar'
import '../welcome/welcome.css'
import ReactMarkdown from 'react-markdown'
import manifesto from './content/manifesto.md'
import audience from './content/audience.md'
import conclusion from './content/conclusion.md'
import goals from './content/goals.md'
import introduction from './content/introduction.md'
import mission from './content/mission.md'
import philosophy from './content/philosophy.md'
import values from './content/values.md'
import outro from './content/outro.md'
import about from './content/about.md'

const panelStyle = {
  backgroundColor: '#f9f9F9',
  fontSize: 16,
  fontWeight: 'lighter'
}

const panelButton = () => {
  return (
    <Button
      size='small'
      style={{
        backgroundImage: 'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
        border: ' 0px solid #64451a',
        borderRadius: '3px',
        color: 'white',
        width: '120px',
        height: '30px',
        boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
      }}
    >
      Learn more
    </Button>
  )
}

function AboutPage () {
  const { Content } = Layout
  const { Title, Paragraph } = Typography
  const { Panel } = Collapse
  const onChange = (key: string | string[]) => {}
  const [manifestoText, setManifestoText] = useState('')
  const [audienceText, setAudienceText] = useState('')
  const [missionText, setMissionText] = useState('')
  const [valuesText, setValuesText] = useState('')
  const [conclusionText, setConclusiontext] = useState('')
  const [introText, setIntroText] = useState('')
  const [goalsText, setGoalsText] = useState('')
  const [philosophyText, setPhilosophyText] = useState('')
  const [outroText, setOutrotext] = useState('')
  const [aboutText, setAboutText] = useState('')

  // Fetch manifesto intro
  useEffect(() => {
    fetch(manifesto)
      .then(res => res.text())
      .then(text => setManifestoText(text))
  }, [])

  useEffect(() => {
    fetch(introduction)
      .then(res => res.text())
      .then(text => setIntroText(text))
  }, [])

  useEffect(() => {
    fetch(audience)
      .then(res => res.text())
      .then(text => setAudienceText(text))
  }, [])

  useEffect(() => {
    fetch(conclusion)
      .then(res => res.text())
      .then(text => setConclusiontext(text))
  }, [])

  useEffect(() => {
    fetch(goals)
      .then(res => res.text())
      .then(text => setGoalsText(text))
  }, [])

  useEffect(() => {
    fetch(mission)
      .then(res => res.text())
      .then(text => setMissionText(text))
  }, [])

  useEffect(() => {
    fetch(philosophy)
      .then(res => res.text())
      .then(text => setPhilosophyText(text))
  }, [])

  useEffect(() => {
    fetch(values)
      .then(res => res.text())
      .then(text => setValuesText(text))
  }, [])

  useEffect(() => {
    fetch(outro)
      .then(res => res.text())
      .then(text => setOutrotext(text))
  }, [])

  useEffect(() => {
    fetch(about)
      .then(res => res.text())
      .then(text => setAboutText(text))
  }, [])

  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <div>
        <Helmet>
          <title>Who are we?</title>
          <meta
            name='description'
            content='BeeHab is website created by nomads for nomads, in the begining it start as a creative project and learning platform'
          />
        </Helmet>
        {/* your page content */}
      </div>
      <ProgressBar
        height='3px'
        color='blue'
        trackBackground='#ef4b78'
        borderRadius='0'
      />
      <Content>
        <Row
          justify='center'
          style={{
            paddingTop: 60,
            alignItems: 'center'
          }}
        >
          <Col
            xs={22}
            sm={22}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{ alignContent: 'center', textAlign: 'center' }}
          >
            <Title
              level={2}
              className='title-3'
              style={{
                color: '#64451a',
                fontWeight: 'lighter'
              }}
            >
              Nomads Tribe <span className='title'>Manifesto</span>
            </Title>
            <Paragraph
              style={{
                paddingTop: 15,
                color: '#535353',
                fontSize: 15,
                fontWeight: 'lighter'
              }}
            >
              <ReactMarkdown children={manifestoText} />
            </Paragraph>
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs={23} sm={23} md={16} lg={16} xl={16} xxl={16}>
            <Collapse
              defaultActiveKey={['1']}
              onChange={onChange}
              style={{}}
              expandIconPosition='end'
            >
              <Panel
                showArrow={false}
                style={panelStyle}
                header='🥰 About Us'
                key='1'
                extra={panelButton()}
              >
                <ReactMarkdown children={introText} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='✨ Who is this for?'
                key='2'
                extra={panelButton()}
              >
                <ReactMarkdown children={missionText} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='😇 Mission'
                key='3'
                extra={panelButton()}
              >
                <ReactMarkdown children={valuesText} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='💚 Beliefs and Values'
                key='4'
                extra={panelButton()}
              >
                <ReactMarkdown children={goalsText} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='🚀 Goals'
                key='5'
                extra={panelButton()}
              >
                <ReactMarkdown children={philosophyText} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='📝 Philosophy'
                key='6'
                extra={panelButton()}
              >
                <ReactMarkdown children={audienceText} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='🎙️ Conclusion'
                key='7'
                extra={panelButton()}
              >
                <ReactMarkdown children={conclusionText} />
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row
          justify='center'
          style={{
            paddingTop: 60,
            alignItems: 'center'
          }}
        >
          <Col
            xs={22}
            sm={22}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{ alignContent: 'center', textAlign: 'center' }}
          >
            <Title
              level={2}
              className='title-3'
              style={{
                color: '#64451a',
                fontWeight: 'lighter'
              }}
            >
              Thank You
            </Title>
            <Paragraph
              style={{
                paddingTop: 15,
                color: '#535353',
                fontSize: 15,
                fontWeight: 'lighter'
              }}
            >
              <ReactMarkdown children={outroText} />
            </Paragraph>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}
export default AboutPage
