//@ts-ignore
import { Directus } from '@directus/sdk'
import { API_URL, API_TOKEN, COLLECTION_STORIES } from '../config'
import { Story } from './Story'

const directus = new Directus(API_URL)
const token = API_TOKEN

export async function getStories (page: number): Promise<Story[]> {
  //await directus.auth.static(token)
  const articleCollection = await directus.items(COLLECTION_STORIES)
  const response = await articleCollection.readByQuery({
    fields: ['id,sort, author, title, cover, slug, chapters.*'],
    filter: {
      status: {
        _eq: 'published'
      }
    },
    page: page,
    limit: 10
  })
  let stories: Story[] = []
  if (response['data']) {
    stories = response['data'].map((item: any) => {
      return new Story(item)
    })
  }
  return stories
}

export async function getStoryBySlug (slug: string): Promise<Story> {
  //await directus.auth.static(token)
  const storyCollection = await directus.items(COLLECTION_STORIES)
  const response = await storyCollection.readByQuery({
    fields: ['id,sort, author, title, cover, opening, slug, chapters.*'],
    filter: {
      slug: {
        _eq: slug
      }
    }
  })

  if (response['data']) {
    let story = new Story(response['data'][0])

    return story
  }
  return new Story({ id: 1 })
}

// get spotlighted story
export async function getSpotLightStory (): Promise<Story> {
  //await directus.auth.static(token)
  const storyCollection = await directus.items(COLLECTION_STORIES)
  const response = await storyCollection.readByQuery({
    fields: ['id,sort, author, title, cover, opening, slug, chapters.*'],
    filter: {
      spotlight: {
        _eq: true
      }
    }
  })
  if (response['data']) {
    let coliving = new Story(response['data'][0])
    return coliving
  }

  return new Story()
}
