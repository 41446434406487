import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Typography, Divider, Card, Button } from 'antd'
import { ASSETS_URL } from '../config'
import { Link } from 'react-router-dom'
// @ts-ignore
import { Helmet } from 'react-helmet'
import '../welcome/welcome.css'
import { Article } from './Article'
import { getArticles } from './ArticleAPI'

function ArticlesPage () {
  const [page, setPage] = useState(1)
  const [more, setMore] = useState(true)
  const [articles, setArticles] = useState<Article[]>([])
  const [loading, setLoading] = useState(false)

  /* Load initial stories batch */
  useEffect(() => {
    try {
      setLoading(true)
      /* load stories data */
      getArticles(page).then((articlesList: any) => {
        setArticles(articlesList)
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
    } finally {
    }
  }, [page])

  /* Handle load more */
  const handleLoadMore = () => {
    let currentPage = page + 1
    if (more) {
      setPage(currentPage)
      getArticles(currentPage).then((qs: Article[]) => {
        if (Array.isArray(qs)) {
          setArticles(articles.concat(qs))
        }
        if (qs.length < 1) {
          setMore(false)
        }
        setLoading(false)
      })
    }
  }

  const { Title, Paragraph } = Typography
  const { Content } = Layout
  const colors = [
    '#f9f8e6',
    '#ff8b8b',
    '#61bfad',
    '#b7e3e4',
    '#efe8d8',
    '#f3c9dd',
    '#f3c9dd',
    '#72aec5',
    '#ef3e4a',
    '#e88565',
    '#eed974'
  ]
  return (
    <Layout>
      <div>
        <Helmet>
          <title>Resources for digital nomads</title>
          <meta
            name='description'
            content='This page is dedicated to helping nomads and aspiring nomads live
              their best lives on the road. We offer a wealth of resources,
              including practical tips, stories of off-the-beaten-path
              adventures, and guides to nomad-friendly destinations. Start your
              journey beyond borders today!'
          />
        </Helmet>
      </div>
      <Content
        style={{
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        <Row
          justify='center'
          style={{
            paddingTop: 60,
            alignItems: 'center'
          }}
        >
          <Col
            xs={22}
            sm={22}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{ alignContent: 'center', textAlign: 'center' }}
          >
            <Title
              level={2}
              className='title-3'
              style={{
                color: '#64451a',
                fontWeight: 'lighter'
              }}
            >
              The Nomad Library
            </Title>
            <Paragraph
              style={{
                paddingTop: 15,
                color: '#535353',
                fontSize: 15,
                fontWeight: 'lighter'
              }}
            >
              Welcome to our library where you’ll find inspiration, resources,
              and information all about digital nomadism
            </Paragraph>
          </Col>
        </Row>
        <Divider></Divider>
        <Row gutter={[16, 16]}>
          {!loading &&
            articles.length > 0 &&
            articles.map((article, index) => (
              <Col id={index.toString()}>
                <Link
                  to={'/discover/'.concat(article.slug)}
                  id={index.toString()}
                >
                  <Card
                    id={index.toString()}
                    style={{
                      width: 300,
                      height: 'auto',
                      backgroundColor:
                        colors[
                          Math.floor(Math.random() * colors.length)
                        ].concat('80'),
                      opacity: 1
                    }}
                    cover={
                      <img
                        alt='example'
                        src={ASSETS_URL + article.cover}
                        width='300px'
                        height='200px'
                        style={{
                          objectFit: 'cover'
                        }}
                      />
                    }
                  >
                    <span
                      style={{
                        fontSize: 16
                      }}
                    >
                      {article.title}
                    </span>
                  </Card>
                </Link>
              </Col>
            ))}
        </Row>
        <Row
          style={{
            paddingTop: 30
          }}
        >
          <Col
            span={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button
              size='large'
              onClick={handleLoadMore}
              style={{
                display: 'flex',
                verticalAlign: 'middle',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid',
                borderImage: 'linear-gradient(to right,#ef4b78, #e8c929) 1',
                borderImageSlice: 1,
                borderRadius: '3px',
                color: '#ef4b78',
                width: '200px',
                height: '50px',
                backgroundColor: 'transparent'
              }}
            >
              {' '}
              {more ? 'More ...' : 'No More :)'}
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ArticlesPage
