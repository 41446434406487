export class Article {
  id: number | undefined
  author: string = ''
  published: Date = new Date()
  updated: Date = new Date()
  cover: string = ''
  intro: string = ''
  main: string = ''
  outro: string = ''
  tags: string = ''
  description: string = ''
  slug: string = ''
  category: string = ''
  title: string = ''
  subTitle: string = ''
  quote: string = ''
  categories: Category[] = []

  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.author) this.author = initializer.author
    if (initializer.published) this.published = initializer.published
    if (initializer.updated) this.updated = initializer.updated
    if (initializer.cover) this.cover = initializer.cover
    if (initializer.intro) this.intro = initializer.intro
    if (initializer.main) this.main = initializer.main
    if (initializer.outro) this.outro = initializer.outro
    if (initializer.tags) this.tags = initializer.tags
    if (initializer.description) this.description = initializer.description
    if (initializer.slug) this.slug = initializer.slug
    if (initializer.category) this.category = initializer.category
    if (initializer.title) this.title = initializer.title
    if (initializer.subtitle) this.subTitle = initializer.subtitle
    if (initializer.quote) this.quote = initializer.quote
    if (initializer.category) {
      this.categories = initializer.category.map(
        (category: any) => new Category(category),
      )
    }
  }
}

export class Category {
  id: number | undefined
  category: string = ''

  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.category) this.category = initializer.category
  }
}
