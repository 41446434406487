import React, { useState, useEffect } from 'react'
import { Layout, Row, Col } from 'antd'
import moment from 'moment'
import { InstagramOutlined } from '@ant-design/icons'
import './welcome.css'
import { useLocation } from 'react-router-dom'

const { Footer } = Layout
function CopyrightSection () {
  const [fullPage, setFullPage] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.indexOf('/story/') === 0) {
      setFullPage(true)
    } else {
      setFullPage(false)
    }
  }, [location])

  const currentYear = moment().format('YYYY')
  if (!fullPage) {
    return (
      <Footer
        style={{
          backgroundColor: 'black',
          color: '#e8c929'
        }}
      >
        <Row
          justify='center'
          align='middle'
          style={{ width: '55%', margin: '0 auto' }}
        >
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{ backgroundColor: 'transparent' }}
          >
            All rights reserved Nomads Tribe {currentYear}
          </Col>

          <Col
            xs={0}
            sm={0}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{ backgroundColor: 'transparent', textAlign: 'right' }}
          >
            <Row gutter={[16, 16]} justify='end'>
              <Col>
                <a
                  href='https://www.instagram.com/nomadstribe_/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <InstagramOutlined
                    style={{ color: '#e8c929', fontSize: '25px' }}
                  />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>
    )
  }
  return <></>
}

export default CopyrightSection
