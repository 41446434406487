import React from 'react'
import { Row, Col, Typography, Divider } from 'antd'

function AboutSection() {
  const { Paragraph } = Typography
  const quotes = [
    'Freedom is the only worthy goal in life. It is won by disregarding things that lie beyond our control.  - Epictetus',
    'The happiness of your life depends upon the quality of your thoughts. - Marcus Aurelius',
    'Joy is not in things; it is in us. - Richard Wagner',
    'Travel and change of place impart new vigor to the mind. - Seneca',
    'To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment. - Ralph Waldo Emerson',
    'Happiness is when what you think, what you say, and what you do are in harmony. - Mahatma Gandhi',
    'The connection between people is the foundation of community. - Jane Goodall',
  ]
  const getRandomQuote = () => {
    return quotes[Math.floor(Math.random() * quotes.length)]
  }
  return (
    <div style={{ backgroundColor: '#f9f9f9', paddingBottom: 40 }}>
      <Divider />
      <Row justify="center">
        <Col
          span={10}
          style={{
            backgroundColor: 'transparent',
          }}
        >
          <Paragraph style={{}}>
            <blockquote>{getRandomQuote()}</blockquote>
          </Paragraph>
        </Col>
      </Row>
    </div>
  )
}

export default AboutSection
