import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Menu, Space, Button, Drawer } from 'antd'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import {
  HeartFilled,
  CompassFilled,
  MessageFilled,
  MenuOutlined,
  FireFilled,
  AudioFilled
} from '@ant-design/icons'

const { Header } = Layout
const iconStyle = {
  color: '#e8c929',
  fontWeight: 'bold'
}

function HeaderSection () {
  const { Item } = Menu
  const [scrolled, setScrolled] = useState(false)
  const [visible, setVisible] = useState(false)
  const [activeKey, setActiveKey] = useState('home')
  const location = useLocation()

  const [fullPage, setFullPage] = useState(false)

  useEffect(() => {
    if (
      location.pathname.indexOf('/story/') === 0 ||
      location.pathname.indexOf('/comatch') === 0 ||
      location.pathname.indexOf('/nomad') === 0 ||
      location.pathname.indexOf('/journey') === 0
    ) {
      setFullPage(true)
    } else {
      setFullPage(false)
    }
  }, [location])

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveKey('home')
    } else {
      setActiveKey(location.pathname.replace('/', ''))
    }
  }, [location])

  const showDrawer = () => {
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }
  const hideDrawer = () => {
    setVisible(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!fullPage) {
    return (
      <Header
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 1,
          transition: 'background-color 0.25s ease-in-out',
          backgroundColor:
            activeKey === 'home'
              ? scrolled
                ? '#f5edc6'
                : 'rgba(220,220,220, 0.3)'
              : '#f5edc6'
        }}
      >
        <Row gutter={10}>
          <Col xs={2} sm={2} md={0} lg={0} xl={0}>
            <Button
              className='menu-button'
              style={{
                backgroundColor: 'transparent'
              }}
              shape='circle'
              type='primary'
              icon={<MenuOutlined />}
              size={'middle'}
              onClick={showDrawer}
            />
          </Col>
          <Drawer
            title='Nomads Tribe'
            placement='right'
            closable={true}
            onClose={onClose}
            visible={visible}
          >
            <Menu
              onSelect={({ key }) => {
                setActiveKey(key)
                hideDrawer()
              }}
              selectedKeys={[activeKey]}
              mode='vertical'
            >
              <Item key='colivings' icon={<CompassFilled style={iconStyle} />}>
                <Link to='/colivings'>Find a coliving</Link>
              </Item>
              <Item key='community' icon={<MessageFilled style={iconStyle} />}>
                <Link to='/community'>Join the tribe</Link>
              </Item>
              <Item key='new' icon={<FireFilled style={iconStyle} />}>
                <Link to='/news'>What's new</Link>
              </Item>
              <Item key='about' icon={<AudioFilled style={iconStyle} />}>
                <Link to='/about'>About</Link>
              </Item>
            </Menu>
          </Drawer>
          <Col
            xs={20}
            sm={20}
            md={2}
            lg={2}
            xl={2}
            style={{
              backgroundColor: 'transparent'
            }}
          >
            <div className='header-logo'>
              <Link key='home' to='/' onClick={() => setActiveKey('home')}>
                <img
                  width='100px'
                  style={{
                    verticalAlign: 'middle'
                  }}
                  src={
                    activeKey === 'home'
                      ? scrolled
                        ? '/assets/beehab-logo-dark.png'
                        : '/assets/beehab-logo.png'
                      : '/assets/beehab-logo-dark.png'
                  }
                  alt='beehab logo dark'
                />
              </Link>
            </div>
          </Col>

          <Col
            xs={0}
            sm={0}
            md={17}
            lg={17}
            xl={17}
            style={{ backgroundColor: 'transparent', paddingLeft: 40 }}
          >
            <div style={{ verticalAlign: 'middle' }}>
              <Menu
                onSelect={({ key }) => setActiveKey(key)}
                selectedKeys={[activeKey]}
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  fontSize: '15px',
                  color: scrolled ? '#64451a' : '#6b6c61',
                  margin: 'revert',
                  borderBottom: '0px'
                }}
                className='header-menu'
                mode='horizontal'
              >
                <Item
                  key='colivings'
                  icon={<CompassFilled style={iconStyle} />}
                >
                  <Link to='/colivings'>Find a coliving</Link>
                </Item>
                <Item
                  key='community'
                  icon={<MessageFilled style={iconStyle} />}
                >
                  <Link to='/community'>Join the tribe</Link>
                </Item>
                <Item key='new' icon={<FireFilled style={iconStyle} />}>
                  <Link to='/news'>What's new</Link>
                </Item>
                <Item key='about' icon={<AudioFilled style={iconStyle} />}>
                  <Link to='/about'>About</Link>
                </Item>
              </Menu>
            </div>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={4}
            lg={4}
            xl={4}
            style={{ backgroundColor: 'transparent' }}
          >
            <div style={{ textAlign: 'right' }}>
              <Space>
                <Button
                  href='/community'
                  className='header-button'
                  style={{
                    backgroundColor: 'transparent'
                  }}
                >
                  Join the tribe
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Header>
    )
  } else {
    return <></>
  }
}

export default HeaderSection
