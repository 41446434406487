import {
  Progress,
  Radio,
  Layout,
  Col,
  Row,
  Typography,
  Image,
  Grid
} from 'antd'
import React, { useState, useEffect } from 'react'
import { Question } from './Quiz'
import MinColivingCard from '../components/MinColivingCard'
import JoinCard from '../components/JoinCard'

import { getQuestions, getColiving } from './QuizAPI'
import './quizz.css'
import { Coliving } from '../coliving/Coliving'

function QuizCoMatch (this: any) {
  /* page title */
  useEffect(() => {
    document.title = 'Coliving Match by Nomads Tribe'
  }, [])

  const { useBreakpoint } = Grid
  const breakpoint = useBreakpoint()
  const anyBreakpoint =
    breakpoint.xl || breakpoint.xxl || breakpoint.lg || breakpoint.md
      ? true
      : false
  /* state and variables */
  const { Title } = Typography
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [answers, setAnswers] = useState<Number[]>([])
  const [selectedTags, setSelectedTags] = useState<Number[]>([])
  const [showResult, setShowResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [questions, setQuestions] = useState<Question[]>([])
  const [coliving, setColiving] = useState<Coliving>()
  const { Content } = Layout

  /* retrieve data from api */
  const getData = () => {
    getQuestions('Comatch').then((qs: any) => {
      setQuestions(qs)
    })
  }

  /* handle submit when user select answer */
  const handleSubmit = (e: any, tags: any) => {
    setAnswers([...answers, e.target.value])
    tags.map((tag: any) => setSelectedTags([...selectedTags, tag]))
    if (currentQuestion === questions.length - 1) {
      setLoading(true)
      setTimeout(() => {
        getColiving(selectedTags).then((response: any) => {
          setColiving(response)
          setLoading(false)
          setShowResult(true)
        })
      }, 1000)
    } else {
      setCurrentQuestion(currentQuestion + 1)
    }
  }

  /* effect to load question and manage loading state */
  useEffect(() => {
    async function loadQuestions () {
      setLoading(true)
      try {
        getData()
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        }
      } finally {
        setLoading(false)
      }
    }
    loadQuestions()
  }, [])

  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <Content>
        <Progress
          percent={(currentQuestion + 1) * (100 / questions.length)}
          showInfo={false}
          strokeColor='#ef4b78'
          style={{
            backgroundColor: '#371722',
            padding: 0,
            margin: 0
          }}
        />
        <Row style={{ padding: 0 }}>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{
              backgroundColor: '#371722',
              paddingLeft: anyBreakpoint ? 50 : 20,
              paddingRight: 20
            }}
          >
            <Row>
              <Col span={24}>
                <Title
                  level={3}
                  style={{
                    fontWeight: 'lighter',
                    color: 'white'
                  }}
                >
                  Coliving Match by Nomads Tribe
                </Title>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  alignContent: 'center',
                  justifyContent: 'center',
                  textAlign: 'center'
                }}
              >
                {loading && (
                  <div style={{ paddingTop: 400 }}>
                    <div className='loading'>
                      <div className='circle'></div>
                      <div className='circle'></div>
                      <div className='circle'></div>
                      <div className='circle'></div>
                    </div>
                  </div>
                )}
                {error && (
                  <div className='row'>
                    <div className='card large error'>
                      <section>
                        <p>
                          <span
                            className='icon-alert inverse '
                            style={{
                              color: 'white',
                              fontSize: 40
                            }}
                          >
                            {error}
                          </span>
                        </p>
                      </section>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            {showResult && (
              <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Title
                        level={2}
                        style={{
                          fontWeight: 'normal',
                          color: 'white'
                        }}
                        className='quizz-question'
                      >
                        Your Next Home Is ...
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {coliving && <MinColivingCard coliving={coliving} />}
                      {!coliving && <div>No Coliving Found for you Sorry</div>}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Title
                        level={2}
                        style={{
                          fontFamily: "'Ubuntu', sans-serif",
                          fontWeight: 'normal',
                          color: 'white',
                          animation: 'inAnimation 8250ms ease-in'
                        }}
                        className='quizz-question'
                      >
                        Be part of an authentic community
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {' '}
                      <JoinCard />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {!showResult && !loading && questions.length > 0 && (
              <Row style={{ paddingTop: 20, paddingBottom: 40 }}>
                <Col span={24}>
                  <Title
                    level={2}
                    style={{
                      fontFamily: "'Ubuntu', sans-serif",
                      fontWeight: 'normal',
                      color: 'white'
                    }}
                    className='quizz-question'
                  >
                    {questions[currentQuestion].question}
                  </Title>
                  <Radio.Group
                    buttonStyle='solid'
                    style={{
                      width: '100%'
                    }}
                  >
                    {questions[currentQuestion].answers.map((option, index) => (
                      <Row style={{ paddingTop: 20 }} key={index}>
                        <Col span={24} style={{ width: '100%' }}>
                          <Radio.Button
                            className='radio-button-hover'
                            style={{
                              backgroundColor: 'white',
                              maxWidth: 400,
                              width: '100%',
                              minHeight: 60,
                              height: '100%',
                              textAlign: 'left',
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: 30,
                              fontFamily: "'Ubuntu', sans-serif",
                              fontSize: 15,
                              color: 'black',
                              border: 'none',
                              paddingTop: 10,
                              paddingBottom: 10
                            }}
                            key={index}
                            value={option.id}
                            onChange={e =>
                              handleSubmit(
                                e,
                                option.tags.flatMap((tag: any) => tag.id)
                              )
                            }
                          >
                            {option.answer}
                          </Radio.Button>
                        </Col>
                      </Row>
                    ))}
                  </Radio.Group>
                </Col>
              </Row>
            )}
          </Col>
          <Col
            xs={0}
            sm={0}
            md={14}
            lg={14}
            xl={14}
            xxl={14}
            style={{
              backgroundColor: '#eee0d2'
            }}
          >
            <Image
              preview={false}
              src='../assets/coquizz.svg'
              height='100vh'
            ></Image>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}
export default QuizCoMatch
