import React from 'react'
import { Coliving } from '../coliving/Coliving'
import { Card, Space, Tag, Avatar, Typography } from 'antd'
import StandardButton from './StandardButton'
import { ASSETS_URL } from '../config'
import './component.css'
import { useNavigate } from 'react-router-dom'

interface CardProps {
  coliving: Coliving
}

function MinColivingCard({ coliving }: CardProps) {
  const { Meta } = Card
  const { Paragraph } = Typography

  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/colivings/'.concat(coliving.slug))
  }
  return (
    <Card
      style={{
        animation: 'inAnimation 2250ms ease-in',
        maxWidth: 400,
        border: '4px solid #ef4b78',
      }}
      size="default"
      type="inner"
      hoverable={true}
      loading={false}
      cover={
        <img
          src={ASSETS_URL + coliving.cover}
          alt={coliving.name}
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            width: '100%',
            height: 200,
            objectFit: 'cover',
          }}
        />
      }
    >
      <Space size={[0, 8]} wrap>
        {coliving.tags.slice(0,6).map((tag) => (
          <Tag key={tag.id} style={{ backgroundColor: 'transparent' }}>
            {' '}
            {tag.tag}{' '}
          </Tag>
        ))}
      </Space>
      <Meta
        style={{ paddingTop: 20 }}
        avatar={<Avatar src={ASSETS_URL + coliving.logo}></Avatar>}
        title={coliving.name}
      />
      <Paragraph
        style={{
          paddingTop: 20,
        }}
        ellipsis={{
          rows: 3,
          expandable: true,
        }}
      >
        {coliving.intro}
      </Paragraph>
      <StandardButton text="Read more ..." onClick={handleClick} />
    </Card>
  )
}
export default MinColivingCard
