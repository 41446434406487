//@ts-ignore
import { Directus } from '@directus/sdk'
import { API_URL } from '../config'
import { Article } from './Article'
import { COLLECTION_ARTICLES } from '../config'

const directus = new Directus(API_URL)


export async function getArticleBySlug (slug: string): Promise<Article> {
  //await directus.auth.static(token)
  const articleCollection = await directus.items(COLLECTION_ARTICLES)
  const response = await articleCollection.readByQuery({
    fields: [
      'id,status,date_created,date_updated,author,cover,intro,outro,main,tags,description,slug,title,subtitle,quote,category.categories_id.*'
    ],
    filter: {
      slug: {
        _eq: slug
      }
    }
  })

  if (response['data']) {
    let article = new Article(response['data'][0])

    return article
  }
  return new Article({ id: 1 })
}

export async function getArticles (page: number): Promise<Article[]> {
  //await directus.auth.static(token)
  const articleCollection = await directus.items(COLLECTION_ARTICLES)
  const response = await articleCollection.readByQuery({
    fields: ['id,status,date_created,date_updated,author,cover,slug,title'],
    filter: {
      status: {
        _eq: 'published'
      }
    },
    page: page,
    limit: 10
  })
  let articles: Article[] = []
  if (response['data']) {
    articles = response['data'].map((item: any) => {
      return new Article(item)
    })
  }
  return articles
}
