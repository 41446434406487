import React from 'react'
import './App.css'
import { ConfigProvider, Layout } from 'antd'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  ScrollRestoration
} from 'react-router-dom'
import ColivingPage from './coliving/ColivingPage'
import ArticlePage from './blog/ArticlePage'
import MainPage from './welcome/WelcomePage'
import './welcome/welcome.css'
import HeaderSection from './components/HeaderSection'
import ExperiencePage from './experience/ExperiencePage'
import ColivingsPage from './colivings/ColivingsPage'
// @ts-ignore
import { Helmet } from 'react-helmet'
import CopyrightSection from './welcome/CopyrightSection'
import NewsletterSection from './welcome/NewsletterSection'
import FooterSection from './welcome/FooterSection'
import TribePage from './tribe/TribePage'
import AboutPage from './about/AboutPage'
import FAQPage from './faq/FAQPage'
import NewPage from './new/NewPage'
import StoryPage from './story/StoryPage'
import StoriesPage from './story/StoriesPage'
import ArticlesPage from './blog/ArticlesPage'
import QuizCoMatch from './quiz/QuizCoMatch'
import QuizLPCoMatch from './quiz/QuizLPCoMatch'
import QuizLPNomadType from './quiz/QuizLPNomadType'
import QuizNomad from './quiz/QuizNomad'
import QuizLPNomadCheck from './quiz/QuizLPNomadCheck'
import QuizNomadCheck from './quiz/QuizNomadCheck'
import ColivingsMapView from './map/MapViewPage'
import NotFoundPage from './notfound/NotFoundPage'

function App () {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Ubuntu, Helvetica,Arial,Lucida Grande,sans-serif'
        }
      }}
    >
      <div>
        <Helmet>
          <title>Find your tribe, find your home</title>
          <meta
            name='description'
            content='Discover the perfect home for your digital nomad journey with our hand-curated list of coliving and working spaces. Experience a sense of community, simplicity, and a touch of humanity and sustainability in nature. Find your next oasis with ease and comfort on our website.'
          />
          <link
            rel='apple-touch-icon'
            href='%PUBLIC_URL%/nomads-logo-min.png'
          />
          <link rel='manifest' href='%PUBLIC_URL%/manifest.json' />

          <meta
            property='og:title'
            content='Nomads Tribe, find your tribe, find your home'
          />
          <meta
            property='og:description'
            content='connects digital nomads with like-minded individuals and communities'
          />
          <meta property='og:image' content='%PUBLIC_URL%/image.jpg' />
          <meta property='og:url' content='https://www.beehab.co/' />
        </Helmet>
        {/* your page content */}
      </div>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout style={{}}>
          <HeaderSection />

          <Routes>
            <Route path='/experience' element={<ColivingsMapView />} />
            <Route path='/' element={<MainPage />} />
            <Route path='/spotlight' element={<ExperiencePage />} />
            <Route path='/colivings' element={<ColivingsPage />} />
            <Route path='/colivings/:slug' element={<ColivingPage />} />
            <Route path='/discover/:slug' element={<ArticlePage />} />
            <Route path='/discover/' element={<ArticlesPage />} />
            <Route path='/community' element={<TribePage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/faq' element={<FAQPage />} />
            <Route path='/news' element={<NewPage />} />
            <Route path='/story/:slug' element={<StoryPage />} />
            <Route path='/stories' element={<StoriesPage />} />
            <Route path='/comatch' element={<QuizLPCoMatch />} />
            <Route path='/nomad' element={<QuizLPNomadType />} />
            <Route path='/journey' element={<QuizLPNomadCheck />} />
            <Route
              path='/comatch/coliving-match-quizz'
              element={<QuizCoMatch />}
            />
            <Route path='/nomad/nomad-type-quizz' element={<QuizNomad />} />
            <Route
              path='/journey/nomad-journey-quizz'
              element={<QuizNomadCheck />}
            />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
          <div style={{ paddingTop: 10 }}></div>
          <NewsletterSection />
          <FooterSection />
          <CopyrightSection />
        </Layout>
      </Router>
    </ConfigProvider>
  )
}
export default App
