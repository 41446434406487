import React, { useEffect } from 'react'
import { Layout, Row, Col, Typography, Button, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import './quizz.css'
// @ts-ignore
import { Helmet } from 'react-helmet'

function QuizLPCoMatch () {
  const { Title, Paragraph } = Typography
  const { Content } = Layout
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/comatch/coliving-match-quizz')
  }
  useEffect(() => {
    document.title = 'Discover your perfect coliving community'
  }, [])

  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <div>
        <Helmet>
          <title>Discover your perfect coliving community</title>
          <meta
            name='description'
            content='Find the perfect coliving for you'
          />
        </Helmet>
        {/* your page content */}
      </div>
      <Content>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{
              backgroundColor: '#371722',
              paddingLeft: 50,
              paddingRight: 20
            }}
          >
            <Row style={{ paddingTop: 50 }}>
              <Col span={24}>
                <div className='lp-header-logo'>
                  <img
                    width='200px'
                    className='logo'
                    style={{
                      verticalAlign: 'middle'
                    }}
                    src={'/assets/beehab-logo.svg'}
                    alt='beehab logo dark'
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title
                  level={1}
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                >
                  Discover your perfect coliving community
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontSize: 18
                  }}
                >
                  Ready to find your next tribe?
                </Paragraph>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'white',
                    fontSize: 18
                  }}
                >
                  Take our quiz to discover the ideal location and community
                  which match your preferences and lifestyle. From urban living
                  to rural retreats, we'll pair you with the perfect spot for
                  your next adventure.
                </Paragraph>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Col span={24}>
                <Button
                  size='large'
                  shape='round'
                  type='primary'
                  htmlType='submit'
                  style={{
                    backgroundImage:
                      'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                    border: ' 0px solid #64451a',

                    color: 'white',

                    height: '40px',
                    boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
                  }}
                  onClick={handleClick}
                >
                  Find my tribe
                </Button>
              </Col>
            </Row>
            <Row style={{ paddingBottom: 20 }}>
              <Col span={24}>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'gray',
                    fontSize: 12
                  }}
                >
                  By doing this quiz, you agree to the NomadsTribe privacy
                  policy
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={14}
            lg={14}
            xl={14}
            xxl={14}
            style={{
              backgroundColor: '#eee0d2'
            }}
          >
            <Image
              preview={false}
              src='../assets/coquizz.svg'
              height='100vh'
            ></Image>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default QuizLPCoMatch
