import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Input, Button, Form } from 'antd'
import { joinNewsLetter } from '../service/JoinAPI'
import './welcome.css'
import { useLocation } from 'react-router-dom'

const { Title, Paragraph } = Typography
function NewsletterSection () {
  const [email, setEmail] = useState<string>('')
  const [sent, setSent] = useState(false)
  const [, setError] = useState(false)
  const location = useLocation()

  const [fullPage, setFullPage] = useState(false)

  useEffect(() => {
    if (location.pathname.indexOf('/story/') === 0) {
      setFullPage(true)
    } else {
      setFullPage(false)
    }
  }, [location])

  const handleSent = async () => {
    try {
      const values = await form.validateFields()
      await addedToNewsLetter(values)
    } catch (error) {}
  }

  const addedToNewsLetter = async (data: any) => {
    try {
      if (!sent) {
        await joinNewsLetter(data.email)
        setSent(true)
        setEmail('')
      }
    } catch (error) {
      setError(true)
      setSent(false)
    }
  }
  const [form] = Form.useForm()
  if (!fullPage) {
    return (
      <Row
        justify='center'
        style={{ paddingTop: '25px', backgroundColor: '#f5edc6' }}
        align='middle'
      >
        <Col span={24} className='centered-col'>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={8}
              xxl={8}
              className='centered-col'
            >
              <div
                style={{
                  width: '300px',
                  height: '300px',
                  maxWidth: 300,
                  maxHeight: 300,
                  backgroundImage: "url('/assets/footer-bg-trans.png')",
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundBlendMode: 'normal',
                  justifyContent: 'middle'
                }}
              ></div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <Row>
                <Col span={24}>
                  <Title className='title'>Your new home is calling</Title>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  {sent && <p>Welcome onboard Nomad.</p>}
                  {!sent && (
                    <div>
                      <Form
                        form={form}
                        name='basic'
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 16 }}
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        autoComplete='off'
                      >
                        <Form.Item
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Email!'
                            },
                            {
                              type: 'email',
                              message: 'Please input a valid Email!'
                            }
                          ]}
                        >
                          <Input
                            size='large'
                            style={{
                              width: '80%',
                              backgroundColor: 'transparent',
                              borderColor: '#64451a',
                              borderRadius: '1%'
                            }}
                            placeholder='Enter your email'
                            value={email}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setEmail(e.target.value)}
                          />
                        </Form.Item>
                      </Form>

                      <Button
                        size='large'
                        type='primary'
                        onClick={() => {
                          handleSent()
                        }}
                        htmlType='submit'
                        style={{
                          backgroundImage:
                            'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                          border: ' 0px solid #64451a',
                          borderRadius: '1px',
                          color: 'white',
                          width: '150px',
                          height: '40px',
                          boxShadow:
                            'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
                        }}
                      >
                        Join the tribe
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
  return <></>
}

export default NewsletterSection
