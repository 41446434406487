import React, { useEffect, useState } from 'react'
import { Layout, Row, Col, Typography, Collapse, Button, Image } from 'antd'
import '../coliving/coliving.css'
// @ts-ignore
import { Helmet } from 'react-helmet'
// @ts-ignore
import ProgressBar from 'react-scroll-progress-bar'
import '../welcome/welcome.css'
import ReactMarkdown from 'react-markdown'
import manifesto from './content/manifesto.md'
import comingFile from './content/coming.md'
import currentFile from './content/current.md'
import '../welcome/welcome.css'

const panelStyle = {
  backgroundColor: '#f9f9F9',
  fontSize: 16,
  fontWeight: 'lighter'
}

const panelButton = () => {
  return (
    <Button
      size='small'
      style={{
        backgroundImage: 'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
        border: ' 0px solid #64451a',
        borderRadius: '3px',
        color: 'white',
        width: '120px',
        height: '30px',
        boxShadow: 'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
      }}
    >
      Learn more
    </Button>
  )
}

function NewPage () {
  const { Content } = Layout
  const { Title, Paragraph } = Typography
  const { Panel } = Collapse
  const onChange = (key: string | string[]) => {}
  const [manifestoText, setManifestoText] = useState('')
  const [coming, setComing] = useState('')
  const [current, setCurrent] = useState('')

  // Fetch manifesto intro
  useEffect(() => {
    fetch(manifesto)
      .then(res => res.text())
      .then(text => setManifestoText(text))
  }, [])
  useEffect(() => {
    fetch(comingFile)
      .then(res => res.text())
      .then(text => setComing(text))
  }, [])

  useEffect(() => {
    fetch(currentFile)
      .then(res => res.text())
      .then(text => setCurrent(text))
  }, [])

  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <div>
        <Helmet>
          <title>What's new in BeeHab</title>
          <meta
            name='description'
            content='BeeHab is website created by nomads for nomads, in the begining it start as a creative project and learning platform'
          />
        </Helmet>
        {/* your page content */}
      </div>
      <ProgressBar
        height='3px'
        color='blue'
        trackBackground='#ef4b78'
        borderRadius='0'
      />
      <Content>
        <Row
          justify='center'
          style={{
            paddingTop: 60,
            alignItems: 'center'
          }}
        >
          <Col
            xs={22}
            sm={22}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{ alignContent: 'center', textAlign: 'center' }}
          >
            <Title
              level={2}
              className='title-3'
              style={{
                color: '#64451a',
                fontWeight: 'lighter'
              }}
            >
              The evolution of <span className='title'>Nomads Tribe</span>
            </Title>

            <Paragraph
              style={{
                paddingTop: 15,
                color: '#535353',
                fontSize: 15,
                fontWeight: 'lighter'
              }}
            >
              <ReactMarkdown children={manifestoText} />
            </Paragraph>
            <Image
              width={120}
              src='../assets/nomads-logo.png'
              alt='nomadstribe logo'
              style={{
                borderRadius: 20
              }}
            />
          </Col>
        </Row>
        <Row
          justify='center'
          style={{
            paddingTop: 30
          }}
        >
          <Col xs={23} sm={23} md={16} lg={16} xl={16} xxl={16}>
            <Collapse
              defaultActiveKey={['1']}
              onChange={onChange}
              style={{}}
              expandIconPosition='end'
            >
              <Panel
                showArrow={false}
                style={panelStyle}
                header='✨ Coming Nomads Tribe v1'
                key='1'
                extra={panelButton()}
              >
                <ReactMarkdown children={coming} />
              </Panel>
              <Panel
                showArrow={false}
                style={panelStyle}
                header='🔥 Current Nomads Tribe v0.1  '
                key='2'
                extra={panelButton()}
              >
                <ReactMarkdown children={current} />
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row
          justify='center'
          style={{
            paddingTop: 60,
            alignItems: 'center'
          }}
        >
          <Col
            xs={22}
            sm={22}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{ alignContent: 'center', textAlign: 'center' }}
          >
            <Title
              level={2}
              className='title-3'
              style={{
                color: '#64451a',
                fontWeight: 'lighter'
              }}
            >
              Thank You
            </Title>
            <Paragraph
              style={{
                paddingTop: 15,
                color: '#535353',
                fontSize: 15,
                fontWeight: 'lighter'
              }}
            >
              If you have any further questions that have not been answered in
              this FAQ section, please get in touch with us at hi@beehab.co. We
              look forward to hearing from you!
            </Paragraph>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}
export default NewPage
