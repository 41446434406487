import React, { useRef } from 'react'
import './welcome.css'
import { Typography, Grid } from 'antd'
const { Text, Title } = Typography

function ContentSection () {
  const { useBreakpoint } = Grid
  const breakpoint = useBreakpoint()
  const anyBreakpoint = breakpoint.xs ? true : false
  const videoRef = useRef<HTMLVideoElement>(null)
  const [showVideo, setShowVideo] = React.useState(true)
  /* Show video or show image */
  const handleVideoEnded = () => {
    setShowVideo(false)
  }

  /* Handle video stop button */
  // eslint-disable-next-line
  const handleStopClick = () => {
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  /* available video */
  const videoFiles = [
    'nomads-1.mp4',
    'nomads-2.mp4',
    'nomads-3.mp4',
    'nomads-4.mp4',
    'nomads-5.mp4'
  ]
  /* video name */
  const randomVideo = videoFiles[Math.floor(Math.random() * videoFiles.length)]
  const sourceVideo = '../assets/videos/' + randomVideo
  return (
    <section className={anyBreakpoint ? 'hero-mobile' : 'hero'} style={{}}>
      <div className='hero-content'>
        {showVideo && (
          <video
            onEnded={handleVideoEnded}
            autoPlay
            loop
            muted
            ref={videoRef}
            style={{ width: '100%', height: '100%' }}
          >
            <source src={sourceVideo} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        )}

        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',

            textAlign: 'center'
          }}
        >
          <Title level={1} className='title'>
            Find your <span>tribe</span>. Find your <span>home</span>.
          </Title>
        </div>
      </div>
    </section>
  )
}

export default ContentSection
