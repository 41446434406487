import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Typography, Card, Image, Button, Grid } from 'antd'
// @ts-ignore
import { Helmet } from 'react-helmet'
import '../welcome/welcome.css'
import './experience.css'
import { Coliving } from '../coliving/Coliving'
import { Story } from '../story/Story'
import { ASSETS_URL } from '../config'
import { getSpotLightStory } from '../story/StoryAPI'
import { getSpotLightTribe } from '../colivings/ColivingAPI'

function ExperiencePage() {
  const { useBreakpoint } = Grid

  const breakpoint = useBreakpoint()
  const anyBreakpoint = breakpoint.xs ? true : false
  const { Content } = Layout
  const { Title, Paragraph } = Typography
  const [coliving, SetColiving] = useState<Coliving>()
  const [story, setStory] = useState<Story>()
  // load one tribe and on story
  useEffect(() => {
    try {
      getSpotLightStory().then((rs) => {
        setStory(rs)
      })
      getSpotLightTribe().then((rs) => {
        SetColiving(rs)
      })
    } catch (e) {

    } finally {
    }
  }, [])

  if (story && coliving) {
    return (
      <Layout>
        <div>
          <Helmet>
            <title>
              One Coliving, One Nomad, One Article, One Theme by nomads tribe
            </title>
            <meta
              name="description"
              content="Each week we present you a coliving, a nomad story, an mindful article about one theme, every week new experience"
            />
          </Helmet>
          {/* your page content */}
        </div>
        <Content>
          <Row
            justify="center"
            style={{
              paddingTop: 64,
            }}
          >
            <Col
              span={24}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Title className="title">
                One Tribe, One Story, One Mindul Reminder
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              xs={23}
              sm={23}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              style={{
                textAlign: 'center',
              }}
            >
              <Paragraph
                ellipsis={{
                  rows: 3,
                }}
                style={{
                  justifyContent: 'center',
                  color: '#64451a',
                }}
              >
                Everyweek we put the spotligh on one coliving to shine , one
                digital or future digital nomads stories to inspire and share
                and finally one mindful ressources for all of us. Each week a
                theme
              </Paragraph>
            </Col>
          </Row>

          <Row
            justify="center"
            style={{
              paddingTop: 40,
            }}
          >
            <Col
              span={24}
              style={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Card
                style={{
                  width: `${anyBreakpoint ? '90%' : '60%'}`,

                  border: '2px solid #ef4b78',
                  backgroundColor: '#efe8d8',
                }}
              >
                <Row
                  style={{
                    height: '100%',
                  }}
                >
                  <Col
                    xs={23}
                    sm={23}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                    style={{}}
                  >
                    <Image
                      src={ASSETS_URL + coliving.cover}
                      preview={false}
                      style={{
                        objectFit: 'contain',

                        maxHeight: 300,
                        width: '100%',
                        borderRadius: 20,
                      }}
                    ></Image>
                  </Col>
                  <Col
                    xs={23}
                    sm={23}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                    style={{
                      justifyContent: 'center',
                      alignContent: 'center',
                      textAlign: 'left',
                      paddingLeft: 30,
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <Title
                          level={2}
                          style={{
                            fontFamily: "'Welcome 2019', sans-serif",
                            color: '#64451a',
                          }}
                        >
                          {coliving.name.concat(' ~ ', coliving.country)}
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Paragraph
                          ellipsis={{
                            rows: 8,
                            expandable: false,
                            symbol: 'more',
                          }}
                          style={{
                            color: '#4e4e4e',
                          }}
                        >
                          {coliving.intro}
                        </Paragraph>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Button
                          href={coliving && '/colivings/'.concat(coliving.slug)}
                          size="large"
                          style={{
                            alignItems: 'center',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            justifyContent: 'center',
                            backgroundImage:
                              'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                            border: ' 0px solid #64451a',
                            borderRadius: '3px',
                            color: 'white',

                            boxShadow:
                              'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)',
                          }}
                        >
                          Read more
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row
            justify="center"
            style={{
              paddingTop: 40,
            }}
          >
            <Col
              span={24}
              style={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Card
                style={{
                  width: `${anyBreakpoint ? '90%' : '60%'}`,

                  border: '2px solid #ff8b8b',
                  backgroundColor: '#f9f8e6',
                }}
              >
                <Row
                  style={{
                    height: '100%',
                  }}
                >
                  <Col
                    xs={23}
                    sm={23}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                    style={{
                      justifyContent: 'center',
                      alignContent: 'center',
                      textAlign: 'left',
                      paddingLeft: 30,
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <Title
                          level={2}
                          style={{
                            fontFamily: "'Welcome 2019', sans-serif",
                            color: '#64451a',
                          }}
                        >
                          {story.title}
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Paragraph
                          ellipsis={{
                            rows: 8,
                            expandable: false,
                            symbol: 'more',
                          }}
                          style={{
                            color: '#4e4e4e',
                          }}
                        >
                          {story.opening}
                        </Paragraph>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Button
                          href={coliving && '/story/'.concat(story.slug)}
                          size="large"
                          style={{
                            alignItems: 'center',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            justifyContent: 'center',
                            backgroundImage:
                              'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                            border: ' 0px solid #64451a',
                            borderRadius: '3px',
                            color: 'white',

                            boxShadow:
                              'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)',
                          }}
                        >
                          Discover
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={23}
                    sm={23}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                    style={{}}
                  >
                    <Image
                      src={ASSETS_URL + story.cover}
                      preview={false}
                      style={{
                        objectFit: 'contain',

                        maxHeight: 300,
                        width: '100%',
                        borderRadius: 20,
                      }}
                    ></Image>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }

  return <div></div>
}

export default ExperiencePage
