import React, { useState, useEffect } from 'react'
import { Row, Col, Menu, Typography } from 'antd'
import { useLocation } from 'react-router-dom'

function FooterSection () {
  const [fullPage, setFullPage] = useState(false)
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.indexOf('/story/') === 0) {
      setFullPage(true)
    } else {
      setFullPage(false)
    }
  }, [location])
  const { Paragraph, Text } = Typography
  if (!fullPage) {
    return (
      <Row
        justify='center'
        style={{ backgroundColor: 'black', height: '300px' }}
        align='middle'
      >
        <Col
          span={12}
          style={{
            height: '100%',
            paddingTop: 50
          }}
        >
          <Row>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8} style={{}}>
              <h4 className='footer-title'>Navigation</h4>
              <div className='contan'>
                <Menu
                  mode='vertical'
                  style={{
                    width: '300px',
                    backgroundColor: 'transparent',
                    color: 'white'
                  }}
                >
                  <Menu.Item
                    key='home'
                    className='custom-menu-item'
                    style={{
                      height: '30px'
                    }}
                  >
                    <a href='/'>Home</a>
                  </Menu.Item>
                  <Menu.Item
                    key='spotlight'
                    className='custom-menu-item'
                    style={{
                      height: '30px'
                    }}
                  >
                    <a href='/spotlight'>SpotLight</a>
                  </Menu.Item>
                  <Menu.Item
                    key='about'
                    className='custom-menu-item'
                    style={{
                      height: '30px'
                    }}
                  >
                    <a href='/about'>About NomadsTribe</a>
                  </Menu.Item>
                  <Menu.Item
                    key='contact'
                    className='custom-menu-item'
                    style={{
                      height: '30px'
                    }}
                  >
                    <a href='/about'>Contact</a>
                  </Menu.Item>
                  <Menu.Item
                    key='faq'
                    className='custom-menu-item'
                    style={{
                      height: '30px'
                    }}
                  >
                    <a href='/faq'>FAQ</a>
                  </Menu.Item>
                </Menu>
              </div>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8} style={{}}>
              <h4 className='footer-title'>About</h4>
              <Paragraph
                ellipsis={{
                  rows: 7,
                  expandable: true,
                  symbol: <span style={{ color: '#ef4b78' }}>more</span>
                }}
                style={{
                  paddingTop: 10,
                  maxWidth: 200,
                  color: 'white',
                  justifyContent: 'center'
                }}
              >
                We believe life is about creating meaningful connections. Our
                platform offers the perfect space to make lifelong friendships
                while exploring the world. Let us take your nomadic journey to
                new heights.
              </Paragraph>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{}}>
              <h4 className='footer-title'>Contact</h4>
              <div style={{ paddingTop: 10 }}>
                <Text style={{ paddingTop: 10, color: 'white' }}>
                  If you need help or just want to say hello, get in touch with
                  us at:{'  '}
                  <Text style={{ paddingTop: 10, color: '#ef4b78' }}>
                    hi@nomadstribe.co
                  </Text>
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
  return <></>
}

export default FooterSection
