import React from 'react'
import { Row, Col, Card, Avatar, Typography } from 'antd'
import {
  MdSurfing,
  MdThumbUp,
  MdTipsAndUpdates,
  MdViewAgenda,
} from 'react-icons/md'

const { Meta } = Card
const { Title, Paragraph } = Typography
function FeaturedNomadi() {
  return (
    <Row
      gutter={16}
      justify="center"
      style={{ paddingTop: '25px', backgroundColor: '#f9f9f9', margin: 0 }}
      align="middle"
    >
      <Col span={24}>
        <Row>
          <Col
            className="centered-col"
            span={24}
            style={{
              textAlign: 'center',
            }}
          >
            <Title level={1} className="title">
              ~ Featured Nomad ~
            </Title>
          </Col>
        </Row>

        <Row
          justify="center"
          style={{ paddingTop: '25px', backgroundColor: '#f9f9f9' }}
          align="middle"
          gutter={10}
        >
          <Col className="centered-col" xs={24} sm={24} md={6}>
            <Card
              cover={
                <img
                  alt="featured nomadi alex"
                  src="../assets/nomadi-1.png"
                  style={{
                    maxHeight: 200,
                    objectFit: 'cover',
                    objectPosition: '100% 20%',
                    borderRadius: '0%',
                  }}
                />
              }
              actions={[
                <MdSurfing key="setting" style={{ color: '#ef4b78' }} />,
                <MdThumbUp key="edit" style={{ color: '#ef4b78' }} />,
                <MdTipsAndUpdates key="setting" style={{ color: '#ef4b78' }} />,
                <MdViewAgenda key="setting" style={{ color: '#ef4b78' }} />,
              ]}
              style={{
                width: '100%',
                borderRadius: '0%',
              }}
            >
              <Meta
                avatar={<Avatar src="../assets/people/Alex.jpeg" />}
                title={
                  <span
                    style={{
                      color: '#ef4b78',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    Alex
                  </span>
                }
                description={
                  <Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: false,
                      symbol: 'more',
                    }}
                    style={{
                      color: '#6c6c6c',
                    }}
                  >
                    Freelance Copywriter ~ Mountain Lover ~ France ~ 4 years
                    Nomad
                  </Paragraph>
                }
              />
            </Card>
          </Col>
          <Col className="centered-col" xs={24} sm={24} md={6}>
            <Card
              cover={
                <img
                  alt="featured nomadi Amena"
                  src="../assets/nomadi-2.png"
                  style={{
                    maxHeight: 200,
                    objectFit: 'cover',
                    objectPosition: '100% 0',
                    borderRadius: '0%',
                  }}
                />
              }
              actions={[
                <MdSurfing key="setting" style={{ color: '#ef4b78' }} />,
                <MdThumbUp key="edit" style={{ color: '#ef4b78' }} />,
                <MdTipsAndUpdates key="setting" style={{ color: '#ef4b78' }} />,
                <MdViewAgenda key="setting" style={{ color: '#ef4b78' }} />,
              ]}
              style={{
                width: '100%',
                borderRadius: '0%',
              }}
            >
              <Meta
                avatar={<Avatar src="../assets/people/Ameena.jpeg" />}
                title={
                  <span
                    style={{
                      color: '#ef4b78',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    Ameena
                  </span>
                }
                description={
                  <Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: false,
                      symbol: 'more',
                    }}
                    style={{
                      color: '#6c6c6c',
                    }}
                  >
                    Professional photographer ~ Painter & Writer ~ UK ~ 2 years
                    Nomad
                  </Paragraph>
                }
              />
            </Card>
          </Col>
          <Col className="centered-col" xs={24} sm={24} md={6}>
            <Card
              cover={
                <img
                  alt="featured nomadi ameena"
                  src="../assets/nomadi-3.png"
                  style={{
                    maxHeight: 200,
                    objectFit: 'cover',
                    objectPosition: '100% 0',
                    borderRadius: '0%',
                  }}
                />
              }
              actions={[
                <MdSurfing key="setting" style={{ color: '#ef4b78' }} />,
                <MdThumbUp key="edit" style={{ color: '#ef4b78' }} />,
                <MdTipsAndUpdates key="setting" style={{ color: '#ef4b78' }} />,
                <MdViewAgenda key="setting" style={{ color: '#ef4b78' }} />,
              ]}
              style={{
                width: '100%',
                borderRadius: '0%',
              }}
            >
              <Meta
                avatar={<Avatar src="../assets/people/Anna.jpeg" />}
                title={
                  <span
                    style={{
                      color: '#ef4b78',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    Anna
                  </span>
                }
                description={
                  <Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: false,
                      symbol: 'more',
                    }}
                    style={{
                      color: '#6c6c6c',
                    }}
                  >
                    Software engineer ~ Entrepreneur ~ USA ~ 3 years Nomad
                  </Paragraph>
                }
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FeaturedNomadi
