import React from 'react'
import { Row, Col, Card, Typography, Button, Space, Tag } from 'antd'
import './welcome.css'

const { Title, Paragraph, Text } = Typography

function DiscoverSection () {
  return (
    <div>
      <Row
        justify='center'
        style={{ paddingTop: '25px', backgroundColor: '#f9f9f9' }}
        align='middle'
      >
        <Col span={24}>
          <Row>
            <Col
              className='centered-col'
              span={24}
              style={{
                textAlign: 'center'
              }}
            >
              <Title level={1} className='title'>
                ~ Discover ~
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={24} className='centered-col'>
              <Card
                style={{
                  maxWidth: 940
                }}
                cover={
                  <img
                    style={{
                      maxWidth: 940,
                      maxHeight: 470,
                      objectFit: 'cover',
                      objectPosition: '100% 0',
                      borderRadius: '0%'
                    }}
                    src='/assets/next-home.jpg'
                    alt='find out your next coliving'
                  ></img>
                }
              >
                <Row>
                  <Col xs={24} sm={24} md={12} style={{ backgroundColor: '' }}>
                    <Space size={[0, 8]} wrap>
                      <Tag
                        style={{
                          color: '#ef4b78'
                        }}
                      >
                        Coliving
                      </Tag>
                      <Tag
                        style={{
                          color: '#ef4b78'
                        }}
                      >
                        Nomads
                      </Tag>
                      <Tag
                        style={{
                          color: '#ef4b78'
                        }}
                      >
                        With Love
                      </Tag>
                    </Space>
                    <Title
                      level={2}
                      style={{
                        color: '#64451a'
                      }}
                    >
                      QUIZ: Match with your perfect coliving space
                    </Title>
                  </Col>
                  <Col xs={24} sm={24} md={12} style={{ backgroundColor: '' }}>
                    <Paragraph
                      ellipsis={{
                        rows: 4,
                        expandable: false,
                        symbol: 'more'
                      }}
                      style={{
                        color: '#6c6c6c'
                      }}
                    >
                      Discover the ideal coliving space for your preferred
                      lifestyle with our insightful quiz. Our questions help
                      identify your needs, passions, and preferences – then, we
                      match you with the best community-based living solution.
                    </Paragraph>
                    <Button
                      size='large'
                      href='comatch/'
                      style={{
                        display: 'flex',

                        alignItems: 'center',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        justifyContent: 'center',
                        backgroundImage:
                          'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                        border: ' 0px solid #64451a',
                        borderRadius: '1px',
                        color: 'white',
                        width: '170px',
                        height: '50px',
                        boxShadow:
                          'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
                      }}
                    >
                      Match me!
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24} className='centered-col'>
          <div
            style={{
              maxWidth: 940,
              width: 940,
              paddingTop: 22,
              paddingBottom: 22
            }}
          >
            <Row gutter={8}>
              <Col xs={24} sm={24} md={12} style={{}}>
                <Card
                  cover={
                    <img
                      src='/assets/cotype.jpg'
                      alt='discover your digital nomads type'
                      style={{
                        maxWidth: 470,
                        maxHeight: 230,

                        objectFit: 'cover',
                        objectPosition: '100% 40%',
                        borderRadius: '0%'
                      }}
                    ></img>
                  }
                >
                  <Text
                    style={{
                      color: '#ef4b78',
                      fontWeight: 'bold',
                      paddingBottom: 0
                    }}
                  >
                    Popular
                  </Text>
                  <Title
                    level={3}
                    style={{
                      color: '#64451a',
                      marginTop: 10
                    }}
                  >
                    QUIZ: What type of digital nomad are you?
                  </Title>
                  <Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                      symbol: 'more'
                    }}
                    style={{
                      color: '#6c6c6c'
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      Do you prefer a slow-paced and immersive experience, or a
                      fast-paced and action-packed lifestyle?
                    </span>
                  </Paragraph>
                  <Button
                    size='large'
                    href='/nomad'
                    style={{
                      display: 'flex',

                      alignItems: 'center',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      justifyContent: 'center',
                      backgroundImage:
                        'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                      border: ' 0px solid #64451a',
                      borderRadius: '1px',
                      color: 'white',
                      width: '170px',
                      height: '50px',
                      boxShadow:
                        'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
                    }}
                  >
                    Tell me more
                  </Button>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Card
                  cover={
                    <img
                      src='/assets/digitalnomad.jpg'
                      alt='is digital nomads right for you'
                      style={{
                        maxWidth: 470,
                        maxHeight: 230,

                        objectFit: 'cover',
                        objectPosition: '100% 0',
                        borderRadius: '0%'
                      }}
                    ></img>
                  }
                >
                  <Text
                    style={{
                      color: '#ef4b78',
                      fontWeight: 'bold',
                      paddingBottom: 0
                    }}
                  >
                    Popular
                  </Text>
                  <Title
                    level={3}
                    style={{
                      color: '#64451a',
                      marginTop: 10
                    }}
                  >
                    Is digital nomadism right for you?
                  </Title>
                  <Paragraph
                    ellipsis={{
                      rows: 3.5,
                      expandable: true,
                      symbol: 'more'
                    }}
                    style={{
                      color: '#6c6c6c'
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      Are you thinking about becoming a digital nomad?{' '}
                    </span>
                    Take this quiz to find out if you have the qualities and
                    habits necessary to thrive in this lifestyle. From your work
                    habits to your travel preferences, this quiz will give you a
                    sense of whether digital nomadism is the right path for you.
                  </Paragraph>
                  <Button
                    size='large'
                    href='/journey'
                    style={{
                      display: 'flex',

                      alignItems: 'center',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      justifyContent: 'center',
                      backgroundImage:
                        'linear-gradient(to right, #ef4f76, #ee855e, #ec9f4f)',
                      border: ' 0px solid #64451a',
                      borderRadius: '1px',
                      color: 'white',
                      width: '170px',
                      height: '50px',
                      boxShadow:
                        'inset 0 -.0625rem 0 0 rgba(var(--black-rgb),.3)'
                    }}
                  >
                    Find out
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default DiscoverSection
