export class Coliving {
  id: number | undefined
  name: string = ''
  intro: string = ''
  logo: string = ''
  cover: string = ''
  tags: Tag[] = []
  country: string = ''
  tribe: string = ''
  tribeCover: string = ''
  home: string = ''
  homeCover: string = ''
  life: string = ''
  lifeCover: string = ''
  culture: string = ''
  cultureCover: string = ''
  webSite: string = ''
  instagram: string = ''
  slug: string = ''
  address: string = ''
  longitude: number = 0
  latitude: number = 0

  constructor(initializer?: any) {

    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.name) this.name = initializer.name
    if (initializer.intro) this.intro = initializer.intro
    if (initializer.logo) this.logo = initializer.logo
    if (initializer.cover) this.cover = initializer.cover
    if (initializer.tags) {
      this.tags = initializer.tags.map((t: any) => new Tag(t.Tags_id))
    }
    if (initializer.country) this.country = initializer.country.location
    if (initializer.tribe) this.tribe = initializer.tribe
    if (initializer.tribe_cover) this.tribeCover = initializer.tribe_cover
    if (initializer.home) this.home = initializer.home
    if (initializer.home_cover) this.homeCover = initializer.home_cover
    if (initializer.life) this.life = initializer.life
    if (initializer.life_cover) this.lifeCover = initializer.life_cover
    if (initializer.culture) this.culture = initializer.culture
    if (initializer.culture_cover) this.cultureCover = initializer.culture_cover
    if (initializer.website) this.webSite = initializer.website
    if (initializer.instagram) this.instagram = initializer.instagram
    if (initializer.slug) this.slug = initializer.slug
    if (initializer.longitude) this.longitude = initializer.longitude
    if (initializer.latitude) this.latitude = initializer.latitude
    if (initializer.address) this.address = initializer.address
  }
}

export class Tag {
  id: number | undefined
  tag: string = ''
  category: string = ''

  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.tag) this.tag = initializer.tag
    if (initializer.category) this.category = initializer.category

  }
}

export class TagRef {
  id: number | undefined
  tag: string = ''
  category: string = ''

  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.tag) this.tag = initializer.tag
    if (initializer.category) this.category = initializer.category
  }
}

export class Location {
  id: number | undefined
  location: string = ''
  constructor(initializer?: any) {
    if (!initializer) return
    if (initializer.id) this.id = initializer.id
    if (initializer.location) this.location = initializer.location
  }
}
