import React, { useState, useEffect, useCallback } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Typography, Layout, Row, Col, Grid, Divider } from 'antd'
import { Button, Drawer, Space } from 'antd'
import type { DrawerProps } from 'antd/es/drawer'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
//@ts-ignore
import { Helmet } from 'react-helmet'
import './map.css'
import { getColivingList, getLocation, getTags } from '../colivings/ColivingAPI'
import { Coliving, Location, TagRef } from '../coliving/Coliving'
import StandardColivingCard from '../components/StandardColivingCard'
import SearchInput from '../colivings/SearchComponent'
import CountrySelect from '../colivings/LocationComponent'
import StandardSelect from '../colivings/StandardSelectComponent'

function ColivingsMapView () {
  // drawer management
  const [open, setOpen] = useState(false)
  const [placement] = useState<DrawerProps['placement']>('right')

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const { Content } = Layout
  const { Title } = Typography

  let myIcon = L.icon({
    iconUrl: '../assets/map-icon.png',
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [-3, -76]
  })

  L.Marker.prototype.options.icon = myIcon
  //map-icon.png

  // Getting data and managing filters
  const [colivingList, setColivingsList] = useState<Coliving[]>([])
  const [loading, setLoading] = useState(false)
  const [locationsList, setLocationsList] = useState<Location[]>([])
  const [homeTags, setHometags] = useState<TagRef[]>([])
  const [lifeTags, setLifeTags] = useState<TagRef[]>([])
  const [serviceTags, setServiceTags] = useState<TagRef[]>([])
  const [prices, setPrices] = useState<TagRef[]>([])
  const [reset, setReset] = useState(false)
  const { useBreakpoint } = Grid
  const breakpoint = useBreakpoint()
  const anyBreakpoint = breakpoint.xs ? true : false

  // Filtering and loading data
  const [filters, setFilters] = useState({
    search: '',
    locations: [] as number[],
    tags: [] as number[]
  })

  const loadColivings = useCallback(async () => {
    setLoading(true)
    setTimeout(() => {
      getColivingList(filters, 1).then((qs: Coliving[]) => {
        setColivingsList(qs)
        setLoading(false)
        setReset(false)
      })
    }, 0)
  }, [filters])

  /* Handle search */
  const handleSearchChange = (event: any) => {
    setFilters({
      ...filters,
      search: event.target.value
    })
  }

  /* Handle location  */
  const handleLocationChange = (selectedIds: number[]) => {
    setFilters({ ...filters, locations: selectedIds })
  }

  /* Handle tags selections */
  const handleTagChange = (selectedIds: number[]) => {
    setFilters({ ...filters, tags: selectedIds })
  }

  /* handle clear all */
  const handleClearAll = () => {
    setReset(true)
    setFilters({
      search: '',
      locations: [],
      tags: []
    })

    loadColivings()
  }

  /* effet to load colivings */
  useEffect(() => {
    try {
      /* load coliving data*/

      loadColivings()
      /* load locations list */
      getLocation().then((locations: any) => {
        setLocationsList(locations)
      })
      /* load prices tags*/

      getTags('price').then((prices: any) => {
        setPrices(prices)
      })
      /* load environment tag : home */
      getTags('home').then((homes: any) => {
        setHometags(homes)
      })
      /* load activities tag : home */
      getTags('life').then((lifes: any) => {
        setLifeTags(lifes)
      })
      /* load offering tag : home */
      getTags('service').then((services: any) => {
        setServiceTags(services)
      })
    } catch (e) {
    } finally {
    }
  }, [loadColivings])

  return (
    <Layout>
      <div>
        <Helmet>
          <title> The world is yours</title>
          <meta
            name='description'
            content='Inspiring tales of digital nomads and coliving members who wander
              the world and explore new communities. We offer insights and
              advice on remote work, coliving, and travel, as well as personal
              stories of adventure and growth. Join us on a journey of discovery
              and connection'
          />
        </Helmet>
      </div>
      <Content>
        <>
          <Drawer
            title='Look for your tribe'
            placement={placement}
            width={anyBreakpoint ? '100%' : 400}
            onClose={onClose}
            open={open}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type='primary'
                  style={{
                    backgroundColor: '#ef4b78'
                  }}
                  onClick={onClose}
                >
                  Apply
                </Button>
              </Space>
            }
          >
            <Row>
              <Col
                span={24}
                style={{
                  border: '1px solid #E7D788'
                }}
              >
                <SearchInput
                  onChange={handleSearchChange}
                  value={filters.search}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24} style={{ border: '1px solid #E7D788' }}>
                <CountrySelect
                  onChange={handleLocationChange}
                  locations={locationsList}
                  reset={reset}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24} style={{ border: '1px solid #E7D788' }}>
                <StandardSelect
                  placeHolder='💰Average price'
                  tags={prices}
                  onChange={handleTagChange}
                  reset={reset}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24} style={{ border: '1px solid #E7D788' }}>
                <StandardSelect
                  placeHolder='🏡 Environment'
                  tags={homeTags}
                  onChange={handleTagChange}
                  reset={reset}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24} style={{ border: '1px solid #E7D788' }}>
                <StandardSelect
                  placeHolder='🏔️ Activities'
                  tags={lifeTags}
                  onChange={handleTagChange}
                  reset={reset}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24} style={{ border: '1px solid #E7D788' }}>
                <StandardSelect
                  placeHolder='🛎️ Offering'
                  tags={serviceTags}
                  onChange={handleTagChange}
                  reset={reset}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Button
                type='text'
                onClick={handleClearAll}
                style={{
                  color: '#64451a'
                }}
              >
                Clear all
              </Button>
            </Row>
          </Drawer>
        </>
        <Row style={{ paddingTop: 60 }}>
          <Col span={24}>
            <div className='App'>
              <div className='filter-section'>
                <Row gutter={16}>
                  <Col>
                    <Button
                      size='small'
                      onClick={showDrawer}
                      style={{
                        display: 'flex',
                        verticalAlign: 'middle',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid',
                        borderImage:
                          'linear-gradient(to right,#ef4b78, #e8c929) 1',
                        borderImageSlice: 1,
                        borderRadius: '3px',
                        color: '#ef4b78',
                        width: '150px',
                        height: '40px',
                        backgroundColor: 'transparent'
                      }}
                    >
                      {' '}
                      Filters
                    </Button>
                  </Col>
                  <Col flex='auto'>
                    {!anyBreakpoint && (
                      <Title
                        level={2}
                        className='title-3'
                        style={{
                          color: '#64451a',
                          fontWeight: 'lighter',
                          textAlign: 'center',
                          margin: 0,
                          verticalAlign: 'center'
                        }}
                      >
                        Find your next <span className='title'>Tribe</span>
                      </Title>
                    )}
                  </Col>
                  <Col>
                    {!anyBreakpoint && (
                      <Button
                        size='small'
                        href='/colivings'
                        style={{
                          display: 'flex',
                          verticalAlign: 'middle',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid',
                          borderImage:
                            'linear-gradient(to right,#ef4b78, #e8c929) 1',
                          borderImageSlice: 1,
                          borderRadius: '3px',
                          color: '#ef4b78',
                          width: '150px',
                          height: '40px',
                          backgroundColor: 'transparent'
                        }}
                      >
                        {' '}
                        List View
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              <MapContainer
                center={[51.505, -0.09]}
                zoom={3}
                scrollWheelZoom={false}
                className='map-container'
              >
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                {!loading &&
                  colivingList.length > 0 &&
                  colivingList.map((coliving, index) => (
                    <Marker position={[coliving.latitude, coliving.longitude]}>
                      <Popup>
                        <StandardColivingCard key={index} coliving={coliving} />
                      </Popup>
                    </Marker>
                  ))}
              </MapContainer>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ColivingsMapView
