import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { TagRef } from '../coliving/Coliving'

interface Props {
  placeHolder: string
  tags: TagRef[]
  onChange: (selectedIds: number[]) => void
  reset: boolean
}

const StandardSelect = ({ placeHolder, tags, onChange, reset }: Props) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const handleSelectChange = (selectedValues: any[]) => {
    const selectedIds = selectedValues.map((value) => value)
    setSelectedValues(selectedValues)
    onChange(selectedIds)
  }
  const filterOption = (input: string, option: any) =>
    option.children.toLowerCase().includes(input.toLowerCase())

  useEffect(() => {
    if (reset) {
      setSelectedValues([])
    }
  }, [reset])
  return (
    <Select
      showSearch
      value={selectedValues}
      mode="multiple"
      onChange={handleSelectChange}
      maxTagCount="responsive"
      allowClear={true}
      style={{
        width: '100%',
        textAlign: 'left',
        borderRadius: 10,
        borderTop: '1px solid #f0f0f0 ',
        borderBottom: '1px solid #f0f0f0 ',
      }}
      placeholder={placeHolder}
      filterOption={filterOption}
    >
      {tags.map((tag) => (
        <Select.Option key={tag.id} value={tag.id}>
          {tag.tag}
        </Select.Option>
      ))}
    </Select>
  )
}

export default StandardSelect
