// @ts-ignore
import { Directus } from '@directus/sdk'
import { Coliving, Location, TagRef } from '../coliving/Coliving'
import { API_URL } from '../config'
import { COLLECTION_COLIVINGS, COLLECTION_LOCATIONS } from '../config'

const directus = new Directus(API_URL)


interface ListProps {
  search: string
  locations: any[]
  tags: any[]
}
// get list of coliving based on pagination and filters

export async function getColivingList (
  { search, locations, tags }: ListProps,
  page: number
): Promise<any> {
  //await directus.auth.static(token)
  const colivingCollection = await directus.items(COLLECTION_COLIVINGS)
  /* Building the query */

  let query = {
    fields: [
      'id, intro, name, logo, cover, tags.*.tag, country.location, slug, address, longitude, latitude'
    ],
    search: search,
    filter: {},
    page: page,
    limit: 10
  }

  //if (locations.length > 0 || tags.length > 0) {
  query.filter = {
    _and: [
      ...(true
        ? [
            {
              status: {
                _eq: 'published'
              }
            }
          ]
        : []),
      ...(locations.length > 0
        ? [
            {
              country: {
                id: {
                  _in: locations
                }
              }
            }
          ]
        : []),
      ...(tags.length > 0
        ? [
            {
              tags: {
                tags_id: {
                  id: {
                    _in: tags
                  }
                }
              }
            }
          ]
        : [])
    ]
  }
  //}

  const response = await colivingCollection.readByQuery(query)
  let colivings: Coliving[] = []
  if (response['data']) {
    colivings = response['data'].map((item: any) => {
      return new Coliving(item)
    })
  }
  return colivings
}

// get list of available locations
export async function getLocation () {
  //await directus.auth.static(token)
  const locationCollection = await directus.items(COLLECTION_LOCATIONS)
  const response = await locationCollection.readByQuery({
    fields: ['id, location']
  })
  let locations: Location[] = []
  if (response['data']) {
    locations = response['data'].map((item: any) => {
      return new Location(item)
    })
  }
  return locations
}

// get list of tags filtered by category
export async function getTags (category: string) {
  //await directus.auth.static(token)
  const tagCollection = await directus.items('Tags')
  const response = await tagCollection.readByQuery({
    filter: {
      category: {
        _eq: category
      }
    }
  })
  let tags: TagRef[] = []
  if (response['data']) {
    tags = response['data'].map((item: any) => {
      return new TagRef(item)
    })
  }
  return tags
}

// get specific coliving (for coliving page)
export async function getColiving (slug: string): Promise<Coliving> {
  //await directus.auth.static(token)
  const colivingCollection = await directus.items(COLLECTION_COLIVINGS)
  const response = await colivingCollection.readByQuery({
    fields: [
      'id,name,intro,address,slug,logo,cover,country.location,tribe,tribe_cover,home,home_cover,life,life_cover,culture,culture_cover,website,instagram,tags.Tags_id.*.*,latitude,longitude'
    ],
    filter: {
      slug: {
        _eq: slug
      }
    }
  })
  if (response['data']) {
    let coliving = new Coliving(response['data'][0])
    return coliving
  }

  return new Coliving()
}

// get featured coliving / tribe
export async function getFeaturedTribe (): Promise<Coliving> {
  //await directus.auth.static(token)
  const colivingCollection = await directus.items(COLLECTION_COLIVINGS)
  const response = await colivingCollection.readByQuery({
    fields: [
      'id,name,intro,logo,cover,country.location,tribe,tribe_cover,home,home_cover,life,life_cover,culture,culture_cover,website,instagram,tags.Tags_id.*.*,slug'
    ],
    filter: {
      featured: {
        _eq: true
      }
    }
  })
  if (response['data']) {
    let coliving = new Coliving(response['data'][0])
    return coliving
  }

  return new Coliving()
}

// get spotlighted coliving / tribe
export async function getSpotLightTribe (): Promise<Coliving> {
  //await directus.auth.static(token)
  const colivingCollection = await directus.items(COLLECTION_COLIVINGS)
  const response = await colivingCollection.readByQuery({
    fields: [
      'id,name,intro,logo,cover,country.location,tribe,tribe_cover,home,home_cover,life,life_cover,culture,culture_cover,website,instagram,tags.Tags_id.*.*,slug'
    ],
    filter: {
      spotlight: {
        _eq: true
      }
    }
  })
  if (response['data']) {
    let coliving = new Coliving(response['data'][0])
    return coliving
  }

  return new Coliving()
}
