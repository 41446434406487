import React from 'react'
import { Layout, Row, Col, Typography, Card, Form, Input, Button } from 'antd'
// @ts-ignore
import { Helmet } from 'react-helmet'
import '../welcome/welcome.css'

function TribePage () {
  const { Content } = Layout
  const { Title } = Typography
  const [form] = Form.useForm()
  return (
    <Layout>
      <div>
        <Helmet>
          <title>Be part of a mindful community</title>
          <meta
            name='description'
            content='Be part of mindful authentic community, given and sharing'
          />
        </Helmet>
        {/* your page content */}
      </div>
      <Content>
        <div
          style={{
            height: '100vh',
            width: '100vw',
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg-community.jpg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        >
          <Row
            justify='center'
            align='middle'
            style={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 64
            }}
          >
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              <Card
                style={{
                  height: '32rem',
                  width: '32rem',
                  backgroundColor: '#dd4561',
                  border: '0px solid transparent'
                }}
              >
                <Row justify='center'>
                  <Col
                    span={18}
                    style={{
                      justifyContent: 'center',
                      alignContent: 'center',
                      textAlign: 'center'
                    }}
                  >
                    <Title
                      level={1}
                      className='title-3'
                      style={{
                        color: '#e8c929'
                      }}
                    >
                      Your tribe is calling
                    </Title>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col
                    style={{
                      justifyContent: 'center',
                      alignContent: 'center',
                      textAlign: 'center'
                    }}
                  >
                    <iframe
                      title='qsdqs'
                      src='https://nomadstribe.substack.com/embed'
                      style={{
                        maxWidth: '500px',
                        width: '400px'
                      }}
                      height='320'
                    ></iframe>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  )
}

export default TribePage
