import React from 'react'
import { Layout, Grid } from 'antd'
import './welcome.css'
import ContentSection from './ContentSection'
import FeaturedSection from './FeaturedSection'
import DividerComponent from './DividerComponent'
import DiscoverSection from './DiscoverSection'
import FeaturedNomadi from './FeaturedNomadSection'
// @ts-ignore
import { Helmet } from 'react-helmet'
import AboutSection from './AboutSection'

function MainPage () {
  const { useBreakpoint } = Grid
  const breakpoint = useBreakpoint()
  const anyBreakpoint = breakpoint.xs ? true : false
  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <div className='header-section'>
        <ContentSection />
        <div>
          <Helmet>
            <title>Find your tribe, find your home</title>
            <meta
              name='description'
              content='Discover the perfect home for your digital nomad journey with our hand-curated list of coliving and working spaces. Experience a sense of community, simplicity, and a touch of humanity and sustainability in nature. Find your next oasis with ease and comfort on our website.'
            />
          </Helmet>
          {/* your page content */}
        </div>
      </div>
      {!anyBreakpoint && <AboutSection />}
      <FeaturedSection />
      <DividerComponent href='/colivings/' title='All Tribes' />
      <DiscoverSection />
      <DividerComponent href='/discover/' title='The Nomad Library' />
      <FeaturedNomadi />
      <DividerComponent href='/stories/' title='Hear from our nomads' />
    </Layout>
  )
}

export default MainPage
